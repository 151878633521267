export default class SmoothScroll {

	constructor(opts = {}) {

		this.scroll    = { y: 0, top: 0, expo:0, lerp: 0, lastTop: 0, direction:'down' };
		this.ease      = opts.ease || 1;
		this.scroller  = document.querySelector(opts.scroller);
		this.container = document.querySelector(opts.container);
		this.elem      = document.querySelector(opts.elem);
		this.lastTime  = 0;
		this.init();

		// //
		// this.onScrollAfterTimer = null;
		// this.onScrollAfter = opts.onScrollAfter || function() {}; // レンダリング完了時のコールバック

	}

	//----------

	init() {
		this.createHeight()
		this.handleEvents()
	}

	createHeight() {
		const h = this.elem.clientHeight - window.innerHeight
		this.height = document.querySelector('.js-sh');
		this.height.style.height = `${h}px`
		this.scroller.appendChild(this.height)
	}

	updateHeight() {
		const h = this.elem.clientHeight - window.innerHeight
		this.height.style.height = `${h}px`
	}

	//----------

	handleEvents() {
		this.onScroll = this.onScroll.bind(this)
		this.scroller.addEventListener('scroll', this.onScroll)
		// this.start()
	}

	removeEvents() {
		this.scroller.removeEventListener('scroll', this.onScroll)
		// this.stop()
	}

	// onLoop() {
	// 	this.onUpdate()
	// 	this.onRAF = requestAnimationFrame(this.onLoop.bind(this))
	// }

	onUpdate() {

		//
		// this.scroll.lerp = this.lerp( this.scroll.top, this.scroll.lerp, this.ease )

		//
		const currentTime = performance.now() * .001;
		const deltaTime   = currentTime - this.lastTime;
		const alpha = Math.exp( -this.ease * 85 * deltaTime );
		this.scroll.expo = this.lerp( this.scroll.expo, this.scroll.top, alpha );
		this.lastTime = currentTime;

		//
		this.scroll.y = this.scroll.expo;

		//
		this.container.scrollTop = this.scroll.y;
	}

	onScroll(e) {
		this.scroll.top = this.scroller.scrollTop;
		this.scroll.direction = this.scroll.top > this.scroll.lastTop ? 'down' : 'up';
		this.scroll.lastTop = this.scroll.top;
	}

	onResize(e) {
		this.updateHeight()
	}

	// start() {
	// 	this.onLoop()
	// }
	// stop() {
	// 	cancelAnimationFrame(this.onRAF)
	// }

	update() {
		this.updateHeight()
	}

	destroy() {
		this.removeEvents()
		this.height.style.height = `0px`;
	}

	//----------

	lerp(from, to, ease) {
		return to + ( from - to ) * ease
	}

}






