// import {Pane} from 'tweakpane';


//
window.STATIC = {
	enable : true,
	power  : 0,
	max    : 3,
	wait   : 0,
	once(){
		let wait = getParam('wait') ? Number( getParam('wait') ) : this.wait;
		if( IS_REDUCED ) wait = 0;
		gsap.to( this, {
			power    : 1,
			duration : wait,
			onUpdate(){
				RESOURCE.onProgressStatic( STATIC.power * STATIC.max );
			},
			onComplete(){
				ROUTE.setChangeTheme();
				ROUTE.setChangePage();
				ROUTE.onChangeGLColor(0);
				onInitFirst();
				onInitWebGL();
			}
		});
	}
};


//
window.lerp = (from, to, ease) =>{
	return to + ( from - to ) * ease;
}


/*
██╗      ██████╗  █████╗ ██████╗
██║     ██╔═══██╗██╔══██╗██╔══██╗
██║     ██║   ██║███████║██║  ██║
██║     ██║   ██║██╔══██║██║  ██║
███████╗╚██████╔╝██║  ██║██████╔╝
╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝
*/
window.WebFont = {

	current : { lang : null },
	isloaded : false,

	yakuhan : 'https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp-noto.min.css',

	onInit(){
		this.current.lang = $html.getAttribute('lang');
		this.onLoad();
	},

	onLoad(){

		if( this.current.lang === 'ja' && !this.isloaded ){

			//===================
			// ADOBE
			//===================
			this.isloaded = true;
			(function(d) {
			let config = {
				kitId: 'uvn8kna',
				scriptTimeout: 3000,
				async: true
			},
			h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
			})(document);

			// //===================
			// // YAKUHAN
			// //===================
			// var headID = document.getElementsByTagName('head')[0];
			// var link = document.createElement('link');
			// link.type = 'text/css';
			// link.rel = 'stylesheet';
			// headID.appendChild(link);
			// link.href = this.yakuhan;

		}
	}
}

//
// window.addEventListener('load' , e =>{

// 	//
// 	console.log('[load - 2]');

// 	//
// 	addEvents();

// 	//
// 	RESOURCE.once();

// 	//
// 	if( DETECT.device.mobile ){
// 		STATIC.enable = true;
// 	} else if( DETECT.device.tablet ){
// 		STATIC.enable = true;
// 	} else {
// 		STATIC.enable = false;
// 	}

// 	//
// 	if( STATIC.enable ){
// 		STATIC.once();
// 	} else {
// 		ROUTE.setChangeTheme();
// 		ROUTE.setChangePage();
// 		ROUTE.onChangeGLColor(0);
// 		onInitFirst();
// 		onInitWebGL();
// 	}

// 	//
// 	// window.pane = new Pane();
// 	// BACKGL.scenes.partyHome.onInitGui();

// });


window.once = function(){

	//
	styleDom.onInit();
	GRID.onResize();

	//
	addEvents();

	//
	RESOURCE.once();

	//
	if( DETECT.device.mobile ){
		STATIC.enable = true;
	} else if( DETECT.device.tablet ){
		STATIC.enable = true;
	} else {
		STATIC.enable = false;
	}

	//
	if( STATIC.enable ){
		STATIC.once();
	} else {
		ROUTE.setChangeTheme();
		ROUTE.setChangePage();
		ROUTE.onChangeGLColor(0);
		onInitFirst();
		onInitWebGL();
	}

}



/*
██╗███╗   ██╗██╗████████╗
██║████╗  ██║██║╚══██╔══╝
██║██╔██╗ ██║██║   ██║
██║██║╚██╗██║██║   ██║
██║██║ ╚████║██║   ██║
╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝
*/
window.onInitWebGL = function(){

	//
	// window.stats = new Stats();
	// stats.showPanel(0);
	// stats.dom.classList.add('statsjs');
	// document.body.appendChild(stats.dom);

	//
	RESOURCE.onInit();

	//
	BACKGL.once();

}




window.onInitFirst = function(){

	//
	ANIM.once();

	//
	styleMarquee.once();
	mouseEvent.once();
	styleUI.once();
	stylePageScroll.once();

	//
	$html.classList.remove('is-loading-b');

	//
	styleDom.onInit();

}

window.onInitSecond = function(){
	styleArchiveScroll.onInit();
}


window.onInitThird = function(){

	//
	styleInview.onInit();
	// cssAnimation.onInit();
	styleBgImg.onInit();
	// styleThumb.onInit( ROUTE.current.slug );

	//
	stylePageScroll.onInit();
	styleCasesScroll.onInit();
	
	//
	BACKGL.onResize();

	//
	if( !DETECT.device.any ){
		WebFont.onInit();		
		document.body.addEventListener('keydown', e => {
			if( styleCasesScroll.ready ) styleCasesScroll.onKeyDown( e.code );
			if( styleArchiveScroll.ready ) styleArchiveScroll.onKeyDown( e.code );
		});
	} else {
		setTimeout(()=>{
			WebFont.onInit();
		},2000);
	}

}


/*
██████╗ ███████╗███████╗███████╗████████╗
██╔══██╗██╔════╝██╔════╝██╔════╝╚══██╔══╝
██████╔╝█████╗  ███████╗█████╗     ██║
██╔══██╗██╔══╝  ╚════██║██╔══╝     ██║
██║  ██║███████╗███████║███████╗   ██║
╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝
*/
window.onReset = function(){
	stylePageScroll.onReset();
	styleInview.onReset();
}







/*
██████╗ ███████╗███████╗██╗███████╗███████╗
██╔══██╗██╔════╝██╔════╝██║╚══███╔╝██╔════╝
██████╔╝█████╗  ███████╗██║  ███╔╝ █████╗
██╔══██╗██╔══╝  ╚════██║██║ ███╔╝  ██╔══╝
██║  ██║███████╗███████║██║███████╗███████╗
╚═╝  ╚═╝╚══════╝╚══════╝╚═╝╚══════╝╚══════╝
*/
// window.onResizeDomBefore = function(){
// 	ROUTE.onReset();
// 	ROUTE.onInit( document );
// }


/*
███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗
██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝
█████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗
██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║
███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║
╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝
*/


window.onWheel = function(e){
	// if( stylePageScroll.ready ){
	// 	e.preventDefault();
	// 	stylePageScroll.onWheel( e );
	// }	
	if( styleCasesScroll.ready ){
		e.preventDefault();
		styleCasesScroll.onWheel( e );
	}
	if( styleArchiveScroll.ready ){
		e.preventDefault();
		styleArchiveScroll.onWheel( e );
	}
}



window.addEvents = function(){

	//
	if( !DETECT.device.any ){


		//==========================
		// MOUSE
		//==========================
		window.addEventListener( 'mousedown' , function(e) {
			mouseEvent.onStart(e);
			if( e.target.tagName != 'A' ) styleArchiveScroll.onMouseStart();
			styleCasesScroll.onMouseStart();
			BACKGL.onMouseDown(e);
		},  { passive: false });

		//
		window.addEventListener( 'mousemove' , function(e) {
			mouseEvent.onMove(e);
			if( mouseEvent.active && e.target.tagName != 'A' ) styleArchiveScroll.onMouseMove();
			if( mouseEvent.active ) styleCasesScroll.onMouseMove();
		},  { passive: false });

		//
		window.addEventListener( 'mouseup' , function(e) {
			mouseEvent.onEnd();
			if( e.target.tagName != 'A' ) styleArchiveScroll.onMouseEnd();
			styleCasesScroll.onMouseEnd();
			BACKGL.onMouseUp(e);
		},  { passive: false });

		//
		document.addEventListener( 'mouseleave' , function(e) {
			mouseEvent.onEnd();
		});


		//==========================
		// RESIZE
		//==========================
		let resizeTimerFirst;
		let resizeTimerSecond;
		let resizeTimerThird;
		window.addEventListener('resize' , e =>{

			//
			if( DETECT.device.any ) return false;

			$html.classList.add('is-resizing');

			document.documentElement.style.setProperty( '--vh', window.innerHeight/100 + 'px');

			//
			__WW__ = window.innerWidth;
			__WH__ = window.innerHeight;

			//
			if( stylePageScroll.ready ) stylePageScroll.onResize();
			GRID.onResize();

			//
			clearTimeout( resizeTimerFirst );
			resizeTimerFirst = setTimeout( function(){
				// onResizeDomBefore();
				ROUTE.onReset();
				ROUTE.onInit( document );
			}, 200 );

			//
			clearTimeout( resizeTimerSecond );
			resizeTimerSecond = setTimeout( function(){
				RESCALE.onResize();
				BACKGL.onResize();
			}, 400 );

			// clearTimeout( resizeTimerSecond );
			// resizeTimerSecond = setTimeout( function(){
			// 	RESCALE.onResize();
			// 	if( stylePageScroll.ready ) stylePageScroll.onResize();
			// 	BACKGL.onResize();
			// }, 300 );

			// //
			// clearTimeout( resizeTimerFirst );
			// resizeTimerFirst = setTimeout( function(){
			// 	// onResizeDomBefore();
			// 	ROUTE.onReset();
			// 	if( stylePageScroll.ready ) stylePageScroll.onResize();
			// 	ROUTE.onInit( document );
			// }, 400 );

			//
			clearTimeout( resizeTimerThird );
			resizeTimerThird = setTimeout( function(){
				$html.classList.remove('is-resizing');
			}, 500 );



		});


	} else {

		//==========================
		// RESIZE
		//==========================
		window.addEventListener("orientationchange", function() {
			if( !DETECT.device.any ) return false;
			location.reload();
		});

	}



	//==========================
	// SCROLL
	//==========================
	if( DETECT.device.any || IS_REDUCED ){
		$window = document.querySelector('#__app');
		$window.addEventListener('scroll', function(e){
			__WT__ = e.target.scrollTop;
			stylePageScroll.onScroll();
		}, { passive : true });
	}



	//==========================
	// TOUCH
	//==========================
	if( DETECT.touch ){

		window.addEventListener( 'touchstart' , function(e) {
			touchEvent.onStart(e);
			styleCasesScroll.onTouchStart();
			if( e.target.tagName != 'A' ) styleArchiveScroll.onTouchStart();
			BACKGL.onMouseDown(e);
		},  { passive: false });

		window.addEventListener( 'touchmove' , function(e) {
			touchEvent.onMove(e);
			styleCasesScroll.onTouchMove();
			if( e.target.tagName != 'A' ) styleArchiveScroll.onTouchMove();
		},  { passive: false });

		window.addEventListener( 'touchend' , function(e) {
			touchEvent.onEnd(e);
			styleCasesScroll.onTouchEnd();
			if( e.target.tagName != 'A' ) styleArchiveScroll.onTouchEnd();
			BACKGL.onMouseUp(e);
		},  { passive: false });

	}


}




























window.GRID = {

	w : window.innerWidth/48,
	landscape : true,

	onResize(){
		
		//
		this.w = window.innerWidth/48;
	
		//
		if( DETECT.device.mobile ){
			BORDER_RADIUS = 40 / window.innerWidth;
		}

		//
		if( window.innerHeight > window.innerWidth ){
			$html.classList.add('is-portrait');
			$html.classList.remove('is-landscape');
			this.landscape = false;
		} else {
			$html.classList.add('is-landscape');
			$html.classList.remove('is-portrait');
			this.landscape = true;
		}

	}
}










/*
██████╗ ███████╗███╗   ██╗██████╗ ███████╗██████╗
██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔════╝██╔══██╗
██████╔╝█████╗  ██╔██╗ ██║██║  ██║█████╗  ██████╔╝
██╔══██╗██╔══╝  ██║╚██╗██║██║  ██║██╔══╝  ██╔══██╗
██║  ██║███████╗██║ ╚████║██████╔╝███████╗██║  ██║
╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝
*/
window.renderForMobile = () => {
	TIME++;
	requestAnimationFrame( renderForMobile );
	onRenderForMobile();
};





// // renderのアップデート
// const onUpdateBefore = () => {
// 	BACKGL.onRenderForDesktop();
// }

// // webgl Scrollのアップデート
// const onUpdateAfter = () => {
// 	BACKGL.onScrollForDesktop();
// }


//
let delayFrameTimer;
let delayFrame = 2;
window.TIME = 0;
window.renderForDesktop = () => {
	requestAnimationFrame( renderForDesktop );

	//
	// onUpdateBefore();
	BACKGL.onRenderForDesktop0();

	//
	clearTimeout( delayFrameTimer );
	delayFrameTimer = setTimeout(()=>{

		if( !ROUTE.anim.flag.ing ){
			mouseEvent.onUpdate();
			if( stylePageScroll.ready ) stylePageScroll.onScroll();
			if( stylePageScroll.ready ) stylePageScroll.onUpdate();
			if( styleCasesScroll.ready ) styleCasesScroll.onUpdate();
			if( styleArchiveScroll.ready ) styleArchiveScroll.onUpdate();
			if( styleMarquee.ready ) styleMarquee.onUpdate();
		}

		//
		BACKGL.onScrollForDesktop();

		//
		BACKGL.onRenderForDesktop1();

		//
		BACKGL.onRenderForDesktop2();

	}, delayFrame );

	//
	TIME++;

	//
	// BACKGL.onRenderForDesktop();

};






//
window.onRenderForMobile = function(){

	//
	if( !ROUTE.anim.flag.ing ){
		if( stylePageScroll.ready ) stylePageScroll.onUpdate();
		if( styleCasesScroll.ready ) styleCasesScroll.onUpdate();
		if( styleArchiveScroll.ready ) styleArchiveScroll.onUpdate();
		if( styleMarquee.ready ) styleMarquee.onUpdate();
	}

	//
	BACKGL.onRenderForMobile();

}
























































































































































































































































