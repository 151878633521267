/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ transition init
*/





window.ROUTE = {

	//
	name : 'home',
	type : 'home',
	slug : null,
	firsttime : true,

	//
	prev    : {},
	current : {},
	next    : {},

	//
	isSwitchLocaleTo : false,

	//
	pageKeys : [],
	pages : {
		index : { power : 0 },
		about : { power : 0 },
		archive : { power : 0 },
		single : { power : 0 },
		contact : { power : 0 },
		notfound : { power : 0 },
		ai : { power : 0 }
	},

	//
	power : 0,

	//
	color : {
		name : null,
		black : 0,
		white : 0,
	},

	//
	once(){

		this.pageKeys = Object.keys( this.pages );

	},


	//
	onMounted( device, data ){

		//===
		console.log( 'ROUTE.onMounted:', data );

		//===
		this.prev = this.current;
		this.current = data.current;

		//===
		this.firsttime = false;

	},

	onChangePage( _s = .6, _e = 'power2.out' ){
		for (let i = 0; i < this.pageKeys.length; i++) {
			const v = this.pageKeys[i];
			if( v === this.current.type ){
				gsap.to( this.pages[v], {
					power : 1,
					duration : _s,
					ease : _e
				});
			} else {
				gsap.to( this.pages[v], {
					power : 0,
					duration : _s,
					ease : _e
				});
			}
		}
	},

	setChangePage(){
		for (let i = 0; i < this.pageKeys.length; i++) {
			const v = this.pageKeys[i];
			if( v === this.current.type ){
				this.pages[v].power = 1;
			} else {
				this.pages[v].power = 0;
			}
		}
	},

	onChangeTheme( _s = .6, _e = 'power2.out' ){
		this.color.name = getThemeColorName( this.current.type );

		//
		if( this.color.name === 'white' ){
			gsap.to( this.color, {
				white : 1,
				black : 0,
				duration : _s,
				ease : _e
			});
		} else {
			gsap.to( this.color, {
				white : 0,
				black : 1,
				duration : _s,
				ease : _e
			});
		}

		//
		$app.dataset.bg = this.color.name;

	},

	setChangeTheme(){
		this.color.name = getThemeColorName( this.current.type );
		if( this.color.name === 'white' ){
			this.color.white = 1;
			this.color.black = 0;
		} else {
			this.color.white = 0;
			this.color.black = 1;
		}
		$app.dataset.bg = this.color.name;
	},

	onStaicRouteTo( path, $router, type ){
		this.next = { type:type };
		$router.push({ path : path });
	},

	onSwitchLocaleTo( path, $router, type ){

		//
		this.isSwitchLocaleTo = true;

		//
		this.anim.flag.ing = true;
		$html.classList.remove('is-tr-end');
		$html.classList.add('is-tr-start');

		//
		killTweens( this.anim.tween.enter );

		//===
		if( !(IS_REDUCED && DETECT.device.any) ) this.onLeaveAnim();

		//===
		if( styleMenu.isopen ) styleMenu.onClose();

		//===
		this.power = 0;
		const tl = gsap.to( this, {
			power : 1,
			duration : ANIM.dom.leave.s,
			ease : ANIM.dom.leave.e,
			onComplete : () => {
				this.next = { type:type };
				$router.push({ path : path });
				this.onReset();
			}
		});		
		this.anim.tween.leave.push( tl );

		//===
		if( !IS_REDUCED ) BACKGL.onRouteLeave();

	},

	//
	anim : {

		//
		flag : {
			ing : false,
		},

		//
		tween : {
			leave : [],
			enter : []
		},

		//
		alpha : 1,
		twist : 0,
		scale : 1,
		zoom  : 1,
		blur  : 0, 
		rotation : {
			x : 0,
			y : 0,
			z : 0,
		},

	},

	//
	leave( el, done ){

		if( !this.isSwitchLocaleTo ){

			//
			this.anim.flag.ing = true;
			$html.classList.remove('is-tr-end');
			$html.classList.add('is-tr-start');

			//
			killTweens( this.anim.tween.enter );

			//===
			if( !(IS_REDUCED && DETECT.device.any) ) this.onLeaveAnim();

			//===
			if( styleMenu.isopen ) styleMenu.onClose();

			//===
			this.power = 0;
			const tl = gsap.to( this, {
				power : 1,
				duration : ANIM.dom.leave.s,
				ease : ANIM.dom.leave.e,
				onComplete : () => {
					this.onReset();
					done();
				}
			});		
			this.anim.tween.leave.push( tl );

			//===
			if( !IS_REDUCED ) BACKGL.onRouteLeave();

		} else {

			done();

		}

		this.isSwitchLocaleTo = false;

	},

	onReset(){
		
		// DOM
		stylePageScroll.onDestroy();
		styleCasesScroll.onDestroy();
		styleArchiveScroll.onDestroy();
		// cssAnimation.onReset();
		styleInview.onReset();

		//WEBGL
		if( BACKGL.scenes.office.ready ) BACKGL.scenes.office.onReset();
		if( BACKGL.scenes.gound.ready ) BACKGL.scenes.gound.onReset();
		if( BACKGL.scenes.teamAll.ready ) BACKGL.scenes.teamAll.onReset();
		if( BACKGL.scenes.teamSingle.ready ) BACKGL.scenes.teamSingle.onReset();
		// BACKGL.scenes.projectThumb.onReset();
		if( BACKGL.scenes.item.ready ) BACKGL.scenes.item.onReset();
		BACKGL.scenes.dom.onDestroy();
		BACKGL.onReset();

	},

	onInit( el ){

		//
		styleDom.onInit( el );
		stylePageScroll.onInit(el);
		styleArchiveScroll.onInit(el);
		// cssAnimation.onInit(el);
		styleInview.onInit(el);

		//WEBGL
		BACKGL.scenes.partySingle.onInit(el);

		if( BACKGL.scenes.partyHome.ready ) BACKGL.scenes.partyHome.onInit(el);

		BACKGL.onInitDom(el);

		BACKGL.scenes.dom.onInit();

		if( BACKGL.scenes.item.ready ) BACKGL.scenes.item.onInit();

		if( BACKGL.scenes.teamAll.ready ) BACKGL.scenes.teamAll.onInit(el);

		//
		switch( this.current.slug ){
			case 'about':
				if( BACKGL.scenes.office.ready && !DETECT.device.any ) BACKGL.scenes.office.onResize();
			break;
		}

		//
		styleCasesScroll.onInit( el );
		styleBgImg.onInit( el );
		// styleThumb.onInit( this.current.slug );

		//
		WebFont.onInit();

	},

	beforeEnter( el ){},

	enter( el, done ){

		killTweens( this.anim.tween.leave );

		//		
		this.anim.flag.ing = false;
		$html.classList.remove('is-tr-start');
		$html.classList.add('is-tr-end');

		//
		this.onChangeTheme();
		this.onChangePage(0.6);
		this.onChangeGLColor(0.6);

		//
		if( !(IS_REDUCED && DETECT.device.any) ) this.onEnterAnim();

		//
		done();

		//
		this.onInit( el );

		// styleBudge.onInit(el);
		// styleBudge.onInit( el );
		// styleClock.onInit( el );

		//===
		if( !IS_REDUCED ) BACKGL.onRouteEnter();

		//
		BACKGL.onResize();

		//
		ACTION.any = true;

		//
		this.isSwitchLocaleTo = false;

	},


	afterEnter( el ){
	}

}

ROUTE.once();






ROUTE.onLeaveAnim = function(){

	const isHomeToHome = this.current.type === 'index' && this.next.type === 'contact' || this.current.type === 'contact' && this.next.type === 'index';

	//
	const tl1 = gsap.timeline();
	tl1.to( this.anim, {
		alpha    : 0,
		duration : ANIM.webgl.leave.s,
		ease     : ANIM.webgl.leave.e,
		delay    : ANIM.webgl.leave.d,
	});
	this.anim.tween.leave.push(tl1);

	//
	const tl2 = gsap.timeline();
	tl2.to( this.anim.rotation, {
		z : -0.5,
		duration : ANIM.webgl.leave.s,
		ease     : ANIM.webgl.leave.e,
		delay    : ANIM.webgl.leave.d,
	});
	this.anim.tween.leave.push(tl2);

	// //
	// var tl = gsap.timeline();
	// tl.to( this.anim, {
	// 	blur : 1,
	// 	duration : ANIM.webgl.leave.s,
	// 	ease     : ANIM.webgl.leave.e,
	// 	delay    : ANIM.webgl.leave.d,
	// });
	// this.anim.tween.leave.push(tl);

	//
	const tl3 = gsap.timeline();
	tl3.to( this.anim, {
		scale : 1.05,
		duration : ANIM.webgl.leave.s,
		ease     : ANIM.webgl.leave.e,
		delay    : ANIM.webgl.leave.d,
	});
	this.anim.tween.leave.push(tl3);


}




ROUTE.onEnterAnim = function( _s = 0, _d = 0 ){

	const isHomeToHome = this.current.type === 'index' && this.prev.type === 'contact' || this.current.type === 'contact' && this.prev.type === 'index';

	// //=============================================
	// // DOM
	// //=============================================
	// var tl = gsap.timeline();
	// tl.to( $window, {
	// 	opacity : 1,
	// 	duration : ANIM.dom.enter.s,
	// 	ease     : ANIM.dom.enter.e,
	// 	delay    : ANIM.dom.enter.d
	// });
	// this.anim.tween.enter.push(tl);


	//=============================================
	// WEBGL
	//=============================================

	//========
	// ALPHA
	//========
	const tl1 = gsap.timeline();
	tl1.set( this.anim, {
		alpha : 0,
	});
	tl1.to( this.anim, {
		alpha    : 1,
		duration : ANIM.dom.enter.s+_s,
		ease     : ANIM.dom.enter.e,
		delay    : ANIM.dom.enter.d+_d,
	});
	this.anim.tween.enter.push(tl1);


	// //========
	// // BLUR
	// //========
	// var tl = gsap.timeline();
	// tl.set( this.anim, {
	// 	blur : 1,
	// });
	// tl.to( this.anim, {
	// 	blur : 0,
	// 	duration : ANIM.webgl.enter.s,
	// 	ease     : ANIM.webgl.enter.e,
	// 	delay    : ANIM.webgl.enter.d,
	// });
	// this.anim.tween.enter.push(tl);

	//========
	// TWIST
	//========
	// var tl = gsap.timeline();
	// tl.set( this.anim, {
	// 	twist : -1,
	// });
	// tl.to( this.anim, {
	// 	twist : 0,
	// 	duration : ANIM.webgl.enter.s/2,
	// 	ease     : ANIM.webgl.enter.e,
	// 	delay    : ANIM.webgl.enter.d,
	// });
	// this.anim.tween.enter.push(tl);

	//========
	// ROTATE
	//========
	const tl2 = gsap.timeline();
	tl2.set( this.anim.rotation, {
		z : 2,
	});
	tl2.to( this.anim.rotation, {
		z : 0,
		duration : ANIM.webgl.enter.s+_s,
		ease     : ANIM.webgl.enter.e,
		delay    : ANIM.webgl.enter.d+_d,
	});
	this.anim.tween.enter.push(tl2);


	//========
	// SCALE
	//========
	const tl3 = gsap.timeline();
	if( this.current.type === 'index' ){
		tl3.set( this.anim, { scale : 1.5 });
	} else {
		tl3.set( this.anim, { scale : 1.2 });
	}
	tl3.to( this.anim, {
		scale : 1,
		duration : (ANIM.webgl.enter.s+_s) * 2,
		ease     : ANIM.webgl.enter.e,
		delay    : ANIM.webgl.enter.d+_d,
	});
	this.anim.tween.enter.push(tl3);

	

}



































