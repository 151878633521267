/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ party
*/

import vert from '~/assets/js/shader/vert/animals.vert';


// import { RenderPass }     from '~/_modules/three-r133/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass }     from '~/_modules/three-r133/examples/jsm/postprocessing/ShaderPass.js';
// import { SavePass }       from '~/_modules/three-r133/examples/jsm/postprocessing/SavePass.js';



export class SCENE_PARTY_SINGLE {

	constructor( models, speed = 10 ){


		this.LIGHTS = [
			{ // 上から
				color: {
					three : COLOR_ORIGIN.lightOrange.three
				},
				position: { x: -4, y: 12, z: 6 },
				intensity: 25,
				distance: 90,
				decay: 0.1,
				mesh : null,
			},
			{ // 正面から 色を乗せる
				color: {
					three : COLOR_ORIGIN.orange.three
				},
				position: { x: 2, y: -2, z: 10 },
				intensity: 12,
				distance: 80,
				decay: 0.1,
				mesh : null,
			},
			{ // 逆光 輪郭を際立たせる
				color: {
					three : COLOR_ORIGIN.deepOrange.three
				},
				position: { x: 0, y: 0, z: -12},
				intensity: 80,
				distance: 100,
				decay: 0.1,
				mesh : null,
			},
		]

		this.UNIFORM = {
			color : {
				three : COLOR_ORIGIN.black2.three,
			},
			emissive : {
				three : COLOR_ORIGIN.deepBrown.three
			},
			roughness: 0.9,
			metalness: 0,
			reflectivity : 0.9,
			specularIntensity: 1.0,
			clearcoat: 0.0,
			clearcoatRoughness: 1.0,
			opacity: 1,
			// twistPower: 1,
			// twistHeight: 1.0,
			// twistAngle: 180.0,
			// wavyPower: 0.2,
			// wavyRadius: 0.75,
			// wavyAngle: 0.1,
		}


		//
		this.models = models;

		//
		this.ready = false;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;
		this.maxHeight = this.height * 1.3;
		this.maxWidth  = this.width  * 3;

		//
		this.max   = 16;
		this.scale = 1.25;


		//mobile
		if( DETECT.device.mobile ){
			this.maxHeight = this.height * 0.35;
			this.maxWidth  = this.width  * 1.5;
			//
			this.max   = 5;
			this.scale = 1.75;
		}

		//
		this.rad = -30 * Math.random() * ( Math.PI / 180 );

		//
		this.speed = speed;

		//
		this.stage = new THREE.Group();

		//
		this.lightGroup = new THREE.Group();
		this.lights = [ ... this.LIGHTS ];



		//
		this._RES = MID_RES;

		//
		this.fbo = new THREE.WebGLRenderTarget(
			this.width *  this._RES,
			this.height * this._RES,
			{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
		);

		//
		this.scene = new THREE.Scene();
		const far = 1/((this.width/this.height)/1.8) * 4;
		this.fog = {
			color : COLOR_ORIGIN.lightBrown.three,
			near  : 0,
			far   : far
		};
		this.scene.fog = new THREE.Fog(
			this.fog.color,
			0,
			this.fog.far * this.width * 0.8
		);
		this.scene.background = COLOR_ORIGIN.lightBrown.three;

		//
		this.fov = 45;
		this.camera = new THREE.PerspectiveCamera( this.fov, this.width / this.height, 1, this.fog.far * this.width );
		this.camera.position.z = this.width * 1.2;
		this.scene.add( this.camera );

		//
		this.array = [];

		//
		this.init();
		this.initMesh();
		this.initLight();

		//
		this.resize();

		//
		this.setHide();
		this.toShow();

		//
		this.ready = true;

		//
		//this.destroy();

	}

	init(){

		this.getPosition();

	}

	getPosition(){

		//
		const minDistance = this.width * 0.45; // 物体同士の最小距離
		let positions = [];

		//
		const material = new THREE.MeshPhysicalMaterial({
			// side : THREE.FrontSide,
			color              : this.UNIFORM.color.three,
			emissive           : this.UNIFORM.emissive.three,
			roughness          : this.UNIFORM.roughness,
			metalness          : this.UNIFORM.metalness,
			clearcoat          : this.UNIFORM.clearcoat,
			clearcoatRoughness : this.UNIFORM.clearcoatRoughness,
			reflectivity       : this.UNIFORM.reflectivity,
			envMap             : null,
			map                : null
		});

		//
		for (let i = 0; i < this.max; i++) {

			const rad = this.rad + 180 * (i / this.max) * (Math.PI / 180);
			let pX = i / this.max * this.maxWidth - this.maxWidth / 2;
			let pY = 0;
			let pZ = 0;
			let position = {};
			let attempts = 20; // 位置を再計算する試行回数

			do {
				if (!(DETECT.device.any && !GRID.landscape)) {
					pY = Math.cos(rad) * this.maxHeight - this.maxHeight / 2 - (Math.random() * this.height) + this.height / 2;
				} else if (DETECT.device.mobile) {
					pY = Math.cos(rad) * this.maxHeight - (Math.random() * this.maxHeight) + this.maxHeight;
				} else {
					this.maxHeight = this.height;
					pY = Math.cos(rad) * this.maxHeight - (Math.random() * this.maxHeight);
				}

				if (DETECT.device.mobile) {
					pZ = Math.random() * this.height * -1 + i * 10 - this.height * 0.3;
				} else {
					pZ = (Math.random() * this.width * -1) - this.width * 0.25 + (i * 10);
				}

				position = { x: pX, y: pY, z: pZ };

				// 他のすべての配置済みの物体との距離を計算
			} while (positions.some(pos => Math.sqrt(Math.pow(pos.x - position.x, 2) + Math.pow(pos.y - position.y, 2) + Math.pow(pos.z - position.z, 2)) < minDistance) && attempts-- > 0);


			if (attempts <= 0) {

				// 適切な距離が見つからない
				console.log('適切な距離が見つからない');

			} else {

				positions.push(position);

				//
				const rotation = {
					x: Math.random() * 360 * (Math.PI / 180),
					y: Math.random() * 360 * (Math.PI / 180),
					z: Math.random() * 360 * (Math.PI / 180)
				};

				//
				const index = Math.floor( Math.random() * this.models.length );

				//
				this.array.push({
					mesh : null,
					material : material,
					geometry : this.models[index].geometry.clone(),
					boundingSphere : this.models[index].boundingSphere,
					rand  : Math.random() * 0.5 + 0.5,
					deg   : i * ( 720/this.max ),
					rad   : 0,
					speed : 0,
					origin : {
						// twistPower : 0,
						// wavyAngle  : 0,
						scale : Math.random() * 0.5 + this.models[index].scale,
						position : position,
						rotation : rotation
					},
					anim : {
						scale : 0,
						reset : [],
						hide : [],
						show : []
					}
				});

			}

		}

	}

	// getPosition(){

		// //
		// for (let i = 0; i < this.max; i++) {

		// 	const material = new THREE.MeshPhysicalMaterial({
		// 		// side               : THREE.DoubleSide,
		// 		color              : this.UNIFORM.color.three,
		// 		emissive           : this.UNIFORM.emissive.three,
		// 		roughness          : this.UNIFORM.roughness,
		// 		metalness          : this.UNIFORM.metalness,
		// 		clearcoat          : this.UNIFORM.clearcoat,
		// 		clearcoatRoughness : this.UNIFORM.clearcoatRoughness,
		// 		reflectivity       : this.UNIFORM.reflectivity,
		// 		// envMap             : RESOURCE.envMaps[ this.uniform.envMap ].tex,
		// 		envMap             : null,
		// 		map                : null
		// 	});
		// 	// material.uniformsNeedUpdate = true;
		// 	// material.needsUpdate = true;
		// 	// material.onBeforeCompile = (shader) => {
		// 	// 	shader.uniforms.twistPower  = { value : this.UNIFORM.twistPower };
		// 	// 	shader.uniforms.twistHeight = { value : this.UNIFORM.twistHeight };
		// 	// 	shader.uniforms.twistAngle  = { value : this.UNIFORM.twistAngle };
		// 	// 	shader.uniforms.wavyPower   = { value : this.UNIFORM.wavyPower };
		// 	// 	shader.uniforms.wavyRadius  = { value : this.UNIFORM.wavyRadius };
		// 	// 	shader.uniforms.wavyAngle   = { value : this.UNIFORM.wavyAngle };
		// 	// 	shader.vertexShader = vert;
		// 	// 	material.userData.shader = shader;
		// 	// };


		// 	//
		// 	const rad = this.rad + 180 * ( i/this.max ) * ( Math.PI / 180 );
		// 	const pX = i/this.max * this.maxWidth - this.maxWidth/2;

		// 	//
		// 	let pY = 0;
		// 	if( !(DETECT.device.any && !GRID.landscape) ){
		// 		pY = Math.cos( rad ) * this.maxHeight - this.maxHeight/2 - (  Math.random() * this.height ) + this.height/2;
		// 	} else if( DETECT.device.mobile ){
		// 		pY = Math.cos( rad ) * this.maxHeight - (  Math.random() * this.maxHeight ) + this.maxHeight;
		// 	} else {
		// 		this.maxHeight = this.height;
		// 		pY = Math.cos( rad ) * this.maxHeight - (  Math.random() * this.maxHeight );
		// 	}


		// 	// const pZ = Math.random() * this.height * -1 + i * 10 - this.height;
		// 	let pZ = ( Math.random() * this.width * -1 ) - this.width*0.25 + (i * 10);

		// 	if( DETECT.device.mobile ){
		// 		// pZ = ( Math.random() * this.width * -1 ) - this.width*0.5 + (i * 10);
		// 		pZ = Math.random() * this.height * -1 + i * 10 - this.height * 0.3;
		// 	}


		// 	const position = {
		// 		x: pX,
		// 		y: pY,
		// 		z: pZ
		// 	}

		// 	//
		// 	const rotation = {
		// 		x: Math.random() * 360 * (Math.PI / 180),
		// 		y: Math.random() * 360 * (Math.PI / 180),
		// 		z: Math.random() * 360 * (Math.PI / 180)
		// 	};

		// 	//
		// 	const index = Math.floor( Math.random() * this.models.length );

		// 	//
		// 	this.array.push({
		// 		mesh : null,
		// 		material : material,
		// 		geometry : this.models[index].geometry.clone(),
		// 		boundingSphere : this.models[index].boundingSphere,
		// 		rand  : Math.random() * 0.5 + 0.5,
		// 		deg   : i * ( 720/this.max ),
		// 		rad   : 0,
		// 		speed : 0,
		// 		origin : {
		// 			// twistPower : 0,
		// 			// wavyAngle  : 0,
		// 			scale : Math.random() * 0.5 + this.models[index].scale,
		// 			position : position,
		// 			rotation : rotation
		// 		},
		// 		anim : {
		// 			scale : 0,
		// 			reset : [],
		// 			hide : [],
		// 			show : []
		// 		}
		// 	});

		// }

	// }

	initMesh(){

		//
		this.array.forEach( (v,i) =>{

			//==========================================
			v.mesh = new THREE.Mesh( v.geometry, v.material );
			v.mesh.visible = false;

			//==========================================
			// 向き変更（軸を変更）
			v.mesh.rotation.x = v.origin.rotation.x;
			v.mesh.rotation.y = v.origin.rotation.y;
			v.mesh.rotation.z = v.origin.rotation.z;

			//==========================================
			// 座標
			v.mesh.position.x = v.origin.position.x;
			v.mesh.position.y = v.origin.position.y;
			v.mesh.position.z = v.origin.position.z;

			//
			v.mesh.scale.x = 0;
			v.mesh.scale.y = 0;
			v.mesh.scale.z = 0;

			//
			this.stage.add( v.mesh );

		});

		//
		this.scene.add( this.stage );

	}

	initLight(){
		this.lights.forEach( (v,i) =>{
			v.mesh = new THREE.PointLight( v.color.three );
			v.mesh.intensity  = v.intensity;
			v.mesh.decay      = v.decay;

			// 20240710
			v.mesh.castShadow = false;

			this.lightGroup.add(v.mesh);
		});
		this.scene.add( this.lightGroup );
	}

	resize(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		if( this.camera ){
			this.camera.aspect = this.width / this.height;
			this.camera.position.z = this.width * 1.2;
			this.camera.updateProjectionMatrix();
		}

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.position.x = v.position.x * RESCALE.r;
				v.mesh.position.y = v.position.y * RESCALE.r;
				v.mesh.position.z = v.position.z * RESCALE.r;
				v.mesh.distance   = v.distance   * RESCALE.r;
			}
		});

	}

	inview( visible ){

		//===
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.visible = visible;
			}
		});

		//===
		for (let i = 0; i < this.array.length; i++) {
			const v = this.array[i];
			v.mesh.visible = visible;
		}

	}

	render(){

		//
		if( !this.ready ) return false;

		//
		for (let i = 0; i < this.array.length; i++) {
			const v = this.array[i];

			//
			if( v.mesh.visible ){

				//
				v.mesh.scale.x = 1 / v.boundingSphere.radius * v.anim.scale * this.scale * RESCALE.r * 2;
				v.mesh.scale.y = 1 / v.boundingSphere.radius * v.anim.scale * this.scale * RESCALE.r * 2;
				v.mesh.scale.z = 1 / v.boundingSphere.radius * v.anim.scale * this.scale * RESCALE.r * 2;

				// //
				// const shader = v.mesh.material.userData.shader;
				// if( shader ){
				// 	shader.uniforms.twistPower.value = v.origin.twistPower;
				// }

				//
				v.deg = ( v.deg <= 720 ) ? v.deg += 0.2 : 0;
				v.rad = v.deg * (Math.PI / 180);
				v.mesh.position.y = v.origin.position.y + Math.sin( v.rad ) * getPxScale(100) * v.rand;

				// this.stage.position.y = stylePageScroll.body.y * 2 + Math.sin( BACKGL.rad ) * 100;

			}
		}

		//
		if( !DETECT.device.any ){
			 this.stage.position.y = stylePageScroll.body.y * 2;
		}

	}

	setHide(){
		this.resetTweens();
		for (let i = 0; i < this.array.length; i++) {
			const v = this.array[i];
			const shader = v.mesh.material.userData.shader;
			v.mesh.visible = false;
			v.anim.scale = 0;
			// if( shader ){
			// 	v.origin.twistPower = 0.025;
			// 	shader.uniforms.twistPower.value = 0.025;
			// }
		}
	}

	setShow(){
		this.resetTweens();
		for (let i = 0; i < this.array.length; i++) {
			const v = this.array[i];
			const shader = v.mesh.material.userData.shader;
			v.mesh.visible = true;
			v.anim.scale = 1;
			// if( shader ){
			// 	v.origin.twistPower = 0;
			// 	shader.uniforms.twistPower.value = 0;
			// }
		}
	}

	resetTweens(){

		for (let n = 0; n < this.array.length; n++) {
			const v = this.array[n];
			killTweens( v.anim.reset );
			killTweens( v.anim.show );
			killTweens( v.anim.hide );
		}

	}

	toShow(){

		this.resetTweens();
		this.array.forEach( ( v, i ) =>{

			const delay = i * 0.2;
			const _s = 2;
			const _e = 'power3.inOut';
			// var _e = 'elastic.out( 0.5, 0.3 )';

			//
			v.mesh.visible = true;

			//
			// v.origin.twistPower = 0.025;
			// var tl = gsap.to( v.origin, {
			// 	duration : _s,
			// 	ease     : _e,
			// 	twistPower : 0,
			// 	delay : delay
			// });
			// v.anim.show.push(tl);

			//
			v.anim.scale = 0;
			const tl = gsap.to( v.anim, {
				duration : _s,
				ease : _e,
				scale : v.origin.scale,
				delay : delay
			});
			v.anim.show.push(tl);

		});

	}

	destroy(){

		const _this = this;

		//
		this.ready = false;
		this.resetTweens();

		//
		this.array.forEach( (v,i) =>{
			if( v.mesh.geometry ) v.mesh.geometry.dispose(); v.mesh.geometry = null;
			if( v.mesh.material ) v.mesh.material.dispose(); v.mesh.material = null;
			this.scene.remove( v.mesh );
			v.mesh = null;
		});
		this.array = null;

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh.geometry ) v.mesh.geometry.dispose(); v.mesh.geometry = null;
			if( v.mesh.material ) v.mesh.material.dispose(); v.mesh.material = null;
			this.scene.remove( v.mesh );
			v.mesh = null;
		});
		this.lights = null;

		//
		this.fbo.dispose();
		this.fbo = null;

		//
		this.scene.remove( this.lightGroup );
		this.lightGroup = null;
		this.scene.remove( this.stage );
		this.stage = null;
		this.scene.remove( this.camera );
		this.camera = null;
		this.scene.fog = null;
		this.scene = null;

		//
		this.fog = null;

	}

}
























































































































































































































































































































































































































































































































































































