export default {
	UPDATE_PAGE( state, page ) {
		state.page = page;
	},
	UPDATE_PROJECTS( state, data ){
		state.projects = data;
	},
	UPDATE_CASES_MOUSEDOWN( state, project ){
		project.action.mousedown = Date.now();
	},
	UPDATE_CASES_MOUSEUP( state, project ){
		project.action.mouseup = Date.now();
	},
	UPDATE_ARCHIVE( state, data ){
		state.archive = data;
	},
	UPDATE_UI_MENU( state, tf ){
		state.ui.menu.isopened = tf;
	},
	UPDATE_CURSOR( state, data ){
		state.ui.cursor = data;
	},
	UPDATE_THUMB( state, slug ){
		state.project_selected_slug = slug;
	},
	UPDATE_TIME( state, data ){
		state.time = data;
	},
	// UPDATE_AI_LANGUAGE( state, data ){
	// 	state.chatAi.lang = data;
	// },
	// UPDATE_AI_ERROR( state, data ){
	// 	state.chatAi.error = data;
	// },
	UPDATE_CHAT_BAN_FLAG( state, data ){
		state.chatAi.ban.flag = data;
	},
	SET_CHAT_SETTING( state, data ){
		state.chatAi.setting.array = data;
	},
	SET_IS_SETTING_LOADED( state, data ){
		state.chatAi.setting.loaded = data;
	},
	SET_CHAT_FAQ( state, data ){
		state.chatAi.faq.array = data;
	},
	SET_IS_FAQ_LOADED( state, data ){
		state.chatAi.faq.loaded = data;
	},
	UPDATE_FAQ_STATUS(state, { question, status }) {
		const item = state.chatAi.faq.array.find((faq) => faq.question.ja === question || faq.question.en === question);
		if (item) {
			item.isUsed = status;
		}
	},
	RESET_FAQ_STATUS(state) {
		state.chatAi.faq.array.forEach( (v,i) =>{
			v.isUsed = false;
		});
	}

};