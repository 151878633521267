export default {

	"replaceText" : {
		"a" : {
			'Map': '<a href="https://g.page/GardenEight" target="_blank" rel="noopener">Map</a>',
			'X': '<a href="https://x.com/GardenEight/" target="_blank" rel="noopener">X</a>',
			'Instagram': '<a href="https://www.instagram.com/garden_eight/" target="_blank" rel="noopener">Instagram</a>',
			'LinkedIn': '<a href="https://www.linkedin.com/company/27122244/admin/feed/posts/" target="_blank" rel="noopener">LinkedIn</a>',
			'Facebook': '<a href="https://www.facebook.com/GardenEight" target="_blank" rel="noopener">Facebook</a>',
			'hello@garden-eight.com': '<a href="mailto:hello@garden-eight.com">hello@garden-eight.com</a>',
			'join@garden-eight.com': '<a href="mailto:join@garden-eight.com">job@garden-eight.com</a>'
		},
		"b" : {
			'ja': {
				'HOME': '<a href="/ja/home">Home</a>',
				'ABOUT': '<a href="/ja/about">About</a>',
				'ARCHIVES': '<a href="/ja/archives">Archives</a>',
				'CASES': '<a href="/ja/cases">Cases</a>',
				'CONTACT' : '<a href="/ja/contact">Contact</a>',
				'TERMS' : '<a href="/ja/terms">Terms</a>',
				'PRIVACY POLICY' : '<a href="/ja/privacy-policy">Privacy Policy</a>',
			},
			'en': {
				'HOME': '<a href="/">Home</a>',
				'ABOUT': '<a href="/about">About</a>',
				'ARCHIVES': '<a href="/archives">Archives</a>',
				'CASES': '<a href="/cases">Cases</a>',
				'CONTACT' : '<a href="/contact">Contact</a>',
				'TERMS' : '<a href="/terms">Terms</a>',
				'PRIVACY POLICY' : '<a href="/privacy-policy">Privacy Policy</a>',
			}
		}
	},

	"error" : {

		"limit": {
			"ja": "APIの使用制限に達しました。現在サービスをご利用いただけません。Error code : 1",
			"en": "The API usage limit has been reached. The service is currently unavailable. Error code : 1"
		},

		"network": {
			"ja": "ネットワークエラーが発生しました。Error code : 2",
			"en": "A network error has occurred. Error code : 2"
		},

		// BAN
		"ban": { 
			"ja": "現在サービスが一時的に利用できません。しばらくしてから再度お試しください。Error code : ",
			"en": "The service is temporarily unavailable. Please try again later. Error code : "
		},

		"server": {
			"ja": "サーバーでエラーが発生しました。Error code : 4",
			"en": "A server error has occurred. Error code : 4"
		},

		"5": {
			"ja": "適切なレスポンスが得られませんでした。Error code : 5",
			"en": "No appropriate response was received. Error code : 5"
		}

	}
}