/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ single
*/

//
import { SCENE_TEAM_SINGLE } from '/assets/js/webgl/class/c-team-single.js';
import teamSingleFrag from '~/assets/js/shader/frag/teamSingle.frag';


const ANIMALS = {
	raccoon: {
		visible: true,
		world : null,
		mesh : null,
		position: { x: 0.5, y: 0.2, z: 0.4, },
		rotation: { x: -87, y: -34, z: -32 },
		scale: 1.1,
	},
	fox: {
		visible: false,
		world : null,
		mesh : null,
		position: { x: 0.5, y: 0.7, z: 0.5, },
		rotation: { x: -65, y: 6, z: -17 },
		scale: .9,
	},
	rabbit: {
		visible: false,
		world : null,
		mesh : null,
		position: { x: 0.1, y: 0, z: 2, },
		rotation: { x: -114, y: 9, z: -21 },
	},
	lizard: {
		visible: false,
		world : null,
		mesh : null,
		position: { x: -0.5, y: -0.2, z: 4, },
		rotation: { x: -94, y: 2.6, z: 44 },
	},
	cat: {
		visible: false,
		world : null,
		mesh : null,
		position: { x: -0.3, y: -0.3, z: 0.5, },
		rotation: { x: -101, y: 12, z: 6 },
	},
	goat: {
		visible: false,
		world : null,
		mesh : null,
		position: { x: -0.5, y: -0.5, z: -2, },
		rotation: { x: -109, y: -1.6, z: 23 },
	},
	koala:{
		visible: false,
		world : null,
		mesh : null,
		position: { x: 0, y: 0, z: 0.5, },
		rotation: { x: -107, y: -5.3, z: -20 },
	},
	hachi:{
		visible: false,
		world : null,
		mesh : null,
		position: { x: 0.2, y: 0.2, z: -3.2, },
		rotation: { x: -60, y: -5.3, z: -15 },
		scale: .6,
	},
}

//
const LIGHTS = [
	{
		type : 'point',
		color: {
			// css : '#fff',
			// three : new THREE.Color('#fff'),
			css : '#f5e7d6',
			three : new THREE.Color('#f5e7d6'),
		},
		position: { x: 2, y: 4, z: -1 },
		mesh : null,
		intensity: 6.0,
		distance: 40,
		decay: 1,
	},
	{
		type : 'point',
		color: {
			// css : '#d5c8b9',
			// three : new THREE.Color('#d5c8b9'),
			css : '#f5e7d6',
			three : new THREE.Color('#f5e7d6'),
		},
		position: { x: -2, y: -2, z: 5 },
		mesh : null,
		intensity: 1.5,
		distance: 30,
		decay: 1,
	}
]



BACKGL.scenes.teamSingle = {

	keys   : [],

	visible : true,

	ready : false,

	enable : true,

	ratio : {
		w : 2.25/12,
		h : 2.25/12 * 1.3
	},

	scale : { x : 1, y : 1, },
	rescale : { x : 0.6, y : 0.6, z : 0.6 },

	uniform : {
		envMap : 'c3',
		// color : {
		// 	css : '#5c5c5c',
		// 	three : new THREE.Color('#5c5c5c')
		// },
		// emissive : {
		// 	css : '#111',
		// 	three : new THREE.Color('#111')
		// },
		color : {
			css : '#b2a592',
			three : new THREE.Color('#b2a592')
		},
		emissive : {
			css : '#181615',
			three : new THREE.Color('#181615')
		},

		// roughness: 0,
		// metalness: 0,
		// reflectivity: 1,
		// clearcoat: 0.1,
		// clearcoatRoughness: 1,

		roughness: 1,
		metalness: 0.6,
		reflectivity: 0.1,
		specularIntensity: 0.1,
		clearcoat: 0.05,
		clearcoatRoughness: 0.25,

		opacity: 1,

		twistPower: 0,
		twistHeight: 1.0,
		twistAngle: 90.0,
		wavyPower: 0.2,
		wavyRadius: 0.75,
		wavyAngle: 0.1,
	},

	lightGroup : new THREE.Group(),
	lights : [ ... LIGHTS ],
	each   : { ... ANIMALS },

	cardSplit : { w:128, h:128, x:128, y:128 },

	setZIndex(){

		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[key];
			v.mesh.position.z = 300;
			v.mesh.renderOrder = 3;
		};

	},

	once(){

		//
		this.width   = BACKGL.width * this.ratio.w;
		this.height  = BACKGL.width * this.ratio.h;
		this.scale.x = BACKGL.width/this.width   * this.rescale.x;
		this.scale.y = BACKGL.height/this.height * this.rescale.y;

		//
		this.lights.forEach( (v,i) =>{
			switch( v.type ){
				case 'point':
					v.mesh = new THREE.PointLight( v.color.three );

					// 20240710
					v.mesh.castShadow = false;

					v.mesh.position.x = v.position.x * this.width / RESCALE.r;
					v.mesh.position.y = v.position.y * this.width / RESCALE.r;
					v.mesh.position.z = v.position.z * this.width / RESCALE.r;
					v.mesh.intensity  = v.intensity;
					v.mesh.distance   = v.distance * this.width / RESCALE.r;
					v.mesh.decay      = v.decay;
					this.lightGroup.add(v.mesh);
				break;
			}
		});

		//
		const light = new THREE.AmbientLight( '#fff', 0.1 );
		
		// 20240710
		light.castShadow = false;
		
		this.lightGroup.add( light );

	},

	onLoaded(){

		if( !this.enable ) return false;

		this.keys = Object.keys( this.each );

		const geometry = new THREE.PlaneBufferGeometry( 1, 1 );

		for (let i = 0; i < this.keys.length; i++) {

			//===
			const key = this.keys[i];
			const v = this.each[key];
			v.tweens = [];
			v.timer = null;
			v.world = new SCENE_TEAM_SINGLE( this.width, this.height, this.ratio, this.scale, key, v.rotation, v.position, this.uniform, this.lightGroup.clone(), this.rescale );

			//
			const material = new THREE.ShaderMaterial({
				vertexShader   : `
					varying vec2 vUv;
					void main(){
						vUv = uv;
						gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
					}
				`,
				fragmentShader : teamSingleFrag,
				uniforms: {
					scale : { value: { x: this.scale.x, y: this.scale.y } },
					borderRadius: { value : 0.125 },
					resolution: { value: { x: 0, y: 0 } },
					fxaaPower : { value: 2 },
					mouseEnterPower : { value: 0 },
					tDiffuse : { value: v.world.fbo.texture },
				},
				transparent: true,
				depthTest : false,
				depthWrite: false
			});
			material.alphaTest = 0.5;
			v.mesh = new THREE.Mesh( geometry, material );
			BACKGL.sceneFrontSide.add( v.mesh );

		};

		this.onIniview();
		this.setZIndex();
		// this.onReset();

		//
		this.ready = true;

	},

	onLoadedEnvMap(){
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[key];
			v.world.initEnvMap();
		};
	},

	onResize(){

		//
		this.width   = BACKGL.width * this.ratio.w;
		this.height  = BACKGL.width * this.ratio.h;
		this.scale.x = BACKGL.width/this.width   * this.rescale.x;
		this.scale.y = BACKGL.height/this.height * this.rescale.y;

		//
		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[key];
			v.world.resize( this.width, this.height );
		};

	},


	onReset(){

		for (let i = 0; i < this.keys.length; i++) {
			const key = this.keys[i];
			const v = this.each[key];
			if( v.mesh && v.world ){
				v.mesh.visible = false;
				v.world.inview( false );
			}
		};

	},

	onIniview( dom ){

		if( dom ){

			//
			const w = this.each[ dom.name ];
			if( w.mesh && w.world ){


				//
				const _min    = ( dom.rect.top + dom.rect.height ) - stylePageScroll.body.y;
				const _max    = stylePageScroll.body.y + __WH__;
				const _inview = ( dom.rect.top < _max && _min > 0 );


				//
				const tx = -__WW__/2 + dom.rect.width/2 + dom.rect.left;
				w.mesh.position.x = tx;

				//
				w.mesh.scale.x = dom.rect.width;
				w.mesh.scale.y = dom.rect.height;
				w.mesh.material.uniforms.scale.x = this.scale.x;
				w.mesh.material.uniforms.scale.y = this.scale.y;
				w.mesh.material.uniforms.resolution.value.x = w.mesh.scale.x;
				w.mesh.material.uniforms.resolution.value.y = w.mesh.scale.y;

				//
				w.mesh.material.uniforms.borderRadius.value = __WW__ / w.mesh.scale.x * BORDER_RADIUS;

				const _visible = dom.visible && _inview;

				//
				w.mesh.visible = _visible;
				w.world.inview( _visible );

				// if( Number(dom.parent.dataset.visible) ){
				// 	w.mesh.visible = true;
				// 	w.world.inview( true );
				// } else {
				// 	w.mesh.visible = false;
				// 	w.world.inview( false );
				// }

			}

		}

	},

	onRender(){
		if( !this.visible ) return false;
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			const key = dom.name;
			const v = this.each[key];
			if( v && dom.visible ){
				BACKGL.renderer.bg.render.setRenderTarget( v.world.fbo );
				BACKGL.renderer.bg.render.render( v.world.scene, BACKGL.p_camera );
			}
		}
	},

	onScroll(){
		for (let i = 0; i < BACKGL.domElements.length; i++) {
			const dom = BACKGL.domElements[i];
			const key = dom.name;
			const v = this.each[key];
			if( v && dom.visible ){
				const ty =  window.innerHeight/2 - dom.rect.height/2 - dom.rect.top;
				v.mesh.position.y = ty + stylePageScroll.body.y;
				v.world.transform.translate.y = v.mesh.position.y * 0.1;
				v.world.render();
			}
		}
	},

	onMouseEnter( e, name, isRight, count ){
		const v = this.each[name];

		if( v.world && !IS_REDUCED ){
			v.world.mouseenter( isRight, 2 );

			//
			clearTimeout( v.timer );
			killTweens( v.tweens );
			if( v.world.zoom > 0.1 && v.world.zoom < 2 ){
				this.onBright( v );
			}

			//
			const tl = gsap.to( v.mesh.material.uniforms.mouseEnterPower, {
				duration : 1,
				ease : 'power2.out',
				value : 1
			});
			v.tweens.push( tl );

		}

	},

	onBright( v ){

		//
		if( Math.abs( v.world.anim.enter.count ) % 10 === 0 ){

			// const tl0 = gsap.timeline();
			// tl0.to( v.mesh.material.uniforms.tBrightPower, {
			// 	duration : .1,
			// 	ease : 'power2.in',
			// 	value : .5
			// });
			// tl0.to( v.mesh.material.uniforms.tBrightPower, {
			// 	duration : 2,
			// 	ease : 'power2.out',
			// 	value : 0
			// });
			// v.tweens.push( tl0 );

			const zoom = getClamp( 1 + ( v.world.anim.enter.count/100) * -1, 0.1, 2 );
			const tl = gsap.to( v.world, {
				duration : 1,
				ease : 'power2.out',
				zoom : zoom,
			});
			v.tweens.push( tl );

		} else {

			// const tl2 = gsap.timeline();
			// tl2.to( v.mesh.material.uniforms.tBrightPower, {
			// 	duration : 2,
			// 	ease : 'power2.out',
			// 	value : 0
			// });
			// v.tweens.push( tl2 );

		}

	},

	onMouseMove(e, name){

		//
		// for (let i = 0; i < BACKGL.domElements.length; i++) {
		// 	var dom = BACKGL.domElements[i];
		// 	var v = this.each[ name ];
		// 	if( dom.name === name && v.mesh && v.world ){
		// 		var cx = ( ( e.offsetX - dom.rect.width/2) / dom.rect.width )  * 2;
		// 		var cy = ( ( e.offsetY - dom.rect.height/2) / dom.rect.height ) * 2;
		// 		var rx = cy * 15 * ( Math.PI / 180 );
		// 		var ry = cx * 15 * ( Math.PI / 180 );
		// 		gsap.to( v.mesh.rotation, {
		// 			duration : 2,
		// 			x : rx,
		// 			y : ry
		// 		});
		// 		// gsap.to( v.world.transform.rotation, {
		// 		// 	duration : 2,
		// 		// 	x : rx,
		// 		// 	z : ry
		// 		// });
		// 	}
		// }


		// var v   = this.each[name];
		// var dom = this.each[name];
		// if( v.mesh ){
		// 	v.mesh.rotation.x = 30 * ( Math.PI / 180 );
		// 	v.mesh.rotation.y = 30 * ( Math.PI / 180 );
		// }

	},

	onMouseLeave(e, name){

		const v = this.each[name];

		if( v.world && !IS_REDUCED ){

			v.world.mouseleave();

			// const tl1 = gsap.to( v.mesh.material.uniforms.mouseEnterPower, {
			// 	duration : 1,
			// 	ease : 'power2.out',
			// 	value : 0
			// });
			// v.tweens.push( tl1 );

			// //
			// const tl2 = gsap.to( v.world, {
			// 	duration : 2,
			// 	ease : 'power2.out',
			// 	zoom : 1,
			// 	delay : 3,
			// 	onStart(){
			// 		v.world.anim.enter.count = 0;
			// 		v.world.transform.rotation.z = 0;
			// 	}
			// });
			// v.tweens.push( tl2 );

		}


		// for (let i = 0; i < BACKGL.domElements.length; i++) {
		// 	var dom = BACKGL.domElements[i];
		// 	var v = this.each[ name ];
		// 	if( dom.name === name && v.mesh && v.world ){
		// 		gsap.to( v.mesh.rotation, {
		// 			duration : 6,
		// 			x : 0,
		// 			y : 0
		// 		});
		// 		gsap.to( v.world.transform.rotation, {
		// 			duration : 6,
		// 			x : 0,
		// 			z : 0
		// 		});
		// 	}
		// }

	}


}


