/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ party
*/

import bokeh from '~/assets/js/shader/frag/bokeh.frag';
// import bokeh from '~/assets/js/shader/frag/bokeh_v2.frag';

import { SCENE_PARTY_SINGLE } from '/assets/js/webgl/class/c-party-single.js';
BACKGL.scenes.partySingle = {

	ready : false,
	visible : true,
	loader : null,

	setZIndex(){
		this.screen.position.z = 1;
	},

	body : {
		el   : null,
	},

	models : [],

	onReset(){
		this.body.el = null;
		this.manager = null;
		this.onDestroy();
	},

	onInit( elem ){

		//
		this.onReset();

		//
		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelectorAll('.Sc')[0]; }

		//
		this.manager = new THREE.LoadingManager();
		this.manager.onLoad = () => {
			this.onLoaded3dModel();
		}

		//
		const el = this.body.el.querySelectorAll('.js-get-3d-model');
		if( !el.length ) return false;

		//
		for (let i = 0; i < el.length; i++) {
			this.models.push({
				el : el[i],
				src : el[i].dataset.src,
				scale : Number( el[i].dataset.scale ),
				loader : null,
				geometry : null,
				boundingSphere : null
			});
		}

		//
		this.models.forEach( (v,i) =>{
			v.loader = new GLTFLoader( this.manager );
			v.loader.setDRACOLoader( dracoLoader );
			v.loader.load( v.src , (gltf) => {
				gltf.scene.traverse( child => {
					const geometries = [];
					switch( child.type ){
						case 'Mesh':
							geometries.push(child.geometry);
							if( child.geometry ) child.geometry.dispose(); child.geometry = null;
							if( child.material ) child.material.dispose(); child.material = null;
							if( child.texture ) child.texture.dispose(); child.texture = null;
						break;
						case 'Group':
							for (let i = 0; i < child.children.length; i++) {
								geometries.push( child.children[i].geometry );
								if( child.geometry ) child.children[i].geometry.dispose(); child.children[i].geometry = null;
								if( child.material ) child.children[i].material.dispose(); child.children[i].material = null;
								if( child.texture ) child.children[i].texture.dispose(); child.children[i].texture = null;
							}
						break;
					}

					//
					v.geometry = BufferGeometryUtils.mergeBufferGeometries( geometries, false );
					if( v.geometry.boundingSphere === null ){
						v.geometry.computeBoundingSphere();
					}
					v.boundingSphere = v.geometry.boundingSphere;

					// _this.world = new SCENE_PARTY_SINGLE( geometry );
					for (let i = 0; i < gltf.scene.children.length; i++) {
						const mesh = gltf.scene.children[i];
						gltf.scene.remove( mesh );
					}
					gltf.scene = null;

				});
			});
		});

	},

	onLoaded3dModel(){

		//===
		if( __WW__ <= 768 ){
			this.uniforms.blurPower.x = getPxScale(3.25) * 2;
			this.uniforms.blurPower.y = getPxScale(3.25) * 2;
		}

		//===
		this.world = new SCENE_PARTY_SINGLE( this.models );

		//===
		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : bokeh,
			// fragmentShader : `
			// 	varying vec2 vUv;
			// 	uniform sampler2D tDiffuse;
			// 	uniform float alpha;
			// 	void main() {
			// 		vec4 _tDiffuse = texture2D( tDiffuse, vUv );
			// 		gl_FragColor = _tDiffuse;
			// 		gl_FragColor.a = _tDiffuse.a * alpha;
			// 	}
			// `,
			uniforms: {
				tDiffuse : { value: this.world.fbo.texture },

				resolution : { value: { x: 0, y: 0 } },

				blurPower  : { value: this.uniforms.blurPower },

				// fogColor   : { value: COLOR.partyBg.three },

				fogColor   : { value: new THREE.Color( this.uniforms.fogColor ) },

				stepPower  : { value: this.uniforms.stepPower },
			},
			transparent: true,
		});
		material.alphaTest = 0.5;
		material.defines = {
			USE_BLUR : ( !IS_LOW_SPEC && !IS_REDUCED )
		}

		this.screen = new THREE.Mesh(geometry, material);
		BACKGL.sceneFrontSide.add( this.screen );

		//===
		this.onResize();
		this.ready = true;

	},

	uniforms : {
		blurPower : {
			x : getPxScale(3.25),
			y : getPxScale(3.25)
		},
		stepPower : 0.5,
		fogColor  : '#f00',
	},

	onDestroy(){

		//
		this.ready = false;

		//
		this.models.forEach( (v,i) =>{
			if( v.boundingSphere ) v.boundingSphere = null;
			if( v.geometry ) v.geometry.dispose(); v.geometry = null;
			v.loader = null;
		});
		this.manager = null;
		this.models = [];

		//
		if( this.screen ){
			this.screen.geometry.dispose();
			this.screen.material.dispose();
			BACKGL.sceneFrontSide.remove( this.screen );
			this.screen.geometry = null;
			this.screen.material = null;
			this.screen = null;
		}

		//
		if( this.world ){
			this.world.destroy();
			this.world = null;
		}


	},

	onResize(){

		this.screen.scale.x = BACKGL.width;
		this.screen.scale.y = BACKGL.height;
		this.screen.material.uniforms.resolution.value.x = BACKGL.width;
		this.screen.material.uniforms.resolution.value.y = BACKGL.height;
		this.world.resize();

	},

	setFadeOut(){

		this.screen.material.uniforms.alpha.value = 0;
		this.screen.visible = false;
		this.world.inview( false );

	},

	onRender(){

		BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
		BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );

	},

	onScroll(){

		this.world.render();

	}

}


