/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ party
*/
// import * as CANNON from '~/_modules/cannon/cannon.min.js';
// import * as CANNON from 'cannon-es';
// import * as BufferGeometryUtils from '~/_modules/three-r133/examples/jsm/utils/BufferGeometryUtils.js';
// 三次元ポイント
// class Point {
// 	constructor(p) {
// 		this.x = p.x;
// 		this.y = p.y;
// 		this.z = p.z;
// 	}
// }
// const array2Point = (arr) => new Point({ x: arr[0], y: arr[1], z: arr[2] });
// const arrays2Point = (arrs) => arrs.map((item) => array2Point(item));


const debugBodyPos = 0;
const debugBallPos = 0;

const phyOptions = {

	// world
	iterations: 1, // 精度 default 10
	tolerance: 1e7, // 寛容さ default 1e-7
	frictionER: 4, // 摩擦緩和 default 3
	contactER: 2, // 接触緩和 default 3
	frictionES: 1e3, // 摩擦係数 default 1e7
	contactES: 1e3, // 接触緩和 default 1e7 (重力他の影響)
	friction: 1, // 摩擦 default 0.3
	restitution: 1, // 復旧 default 0.3

	// body
	SpringMass: 1, // 質量 (mode0用)
	ConeTwistMass: 1, // 質量 (mode4用)
	linearDamping: .2, // 0.01 | 速度減衰
	angularDamping: .4, // 0.01 | 回転減衰
	angularDampingToggle: 1, // 0.01 | 回転減衰 toggle時は強く
	velocity: {x: 0, y: 0, z: 1}, // 速度
	angularVelocity: {x: 0, y: 0, z: 0}, // 回転速度

	// connecting
	Spring: {
		restLength : getPxReScale(10), // A number > 0. Default: 1
		stiffness  : 1, // A number >= 0. Default: 100
		damping    : getPxReScale(100), // A number >= 0. Default: 1
	},
	ConeTwist: {
	},

	// mouse tracking ball
	// ballSize: 1/RESCALE.r * 0.7 * window.innerWidth,
	// ballPosZ: 1/RESCALE.r * 0.7 * window.innerWidth * -2,
	ballSize: getPxReScale(window.innerWidth) * 1,
	ballSizeMobile: getPxReScale(window.innerWidth) * 2.5,
	ballPosZ: getPxReScale(-1500),
	ballPosZMobile: getPxReScale(-1000),
	ballPower: 1,
}

//
export class SCENE_PARTY_PHYSICS {

	constructor( PARTY, PARTY_ANIMALS, LENGTH, POSITIONS, ROTATIONS, PARTY_SCALE){

		this.isVisibleBody = false;
		this.partsScale = 0;
		this.conversionRate = 0.015;
		this.rescale = RESCALE.r;
		this.phyOptions = phyOptions;
		this.modelParts = {
			// x,-z,y
			'raccoon': {
				type: [ 'sphere', 'box', 'box', 'sphere', 'box', 'box' ],
				size: [ 18,999,999, 35,50,15, 35,50,15, 25,999,999, 10,15,20, 10,15,20 ],
				position: [ 0,0,40, 0,-5,30, 0,-5,-10, 0,6,-5, 0,0,-35, 0,-15,-55 ],
			},
			'fox': {
				type: [ 'sphere', 'box', 'box', 'sphere', 'box' ],
				size: [ 20,999,999, 25,45,10, 25,45,10, 25,999,999, 20,20,75 ],
				position: [ 0,10,60, 0,-5,45, 0,-5,5, 0,2,10, 0,10,-45 ],
			},
			'rabbit': {
				type: [ 'box', 'box', 'sphere', 'sphere' ],
				size: [ 20,35,6, 25,20,30, 13,99,99, 14,99,99 ],
				position: [ 0,0,10, 0,-2,5, 0,-2,14, 0,-5,-10 ],
			},
			'lizard': {
				type: [ 'box', 'box', 'box' ],
				size: [ 16,8,28, 30,10,35, 8,5,25 ],
				position: [ 0,5,24, 0,-5,0, 0,-7,-25 ],
			},
			'cat': {
				type: [ 'sphere', 'box', 'box' ],
				size: [ 15,99,99, 30,38,40, 5,8,30 ],
				position: [ 0,5,28, 0,0,5, 0,2,-27 ],
			},
			'goat': {
				type: [ 'sphere', 'sphere', 'box', 'box', 'box', 'sphere' ],
				size: [ 20,999,999, 15,999,999, 30,65,12, 30,65,12, 30,40,40, 25,999,999 ],
				position: [ 0,28,40, 0,12,20, 0,-10,10, 0,-10,-40, 0,5,-20, 0,5,-35 ],
			},
			'koala': {
				type: [ 'sphere', 'sphere' ],
				size: [ 16,99,99, 22,99,99 ],
				position: [ 0,15,4, 0,-10,2 ],
			},
			'hachi': {
				type: [ 'box', 'box', 'box' ],
				size: [ 30,30,30, 40,40,40, 25,25,25 ],
				position: [ 0,0,20, 0,-5,-10, 0,20,-35 ],
			}
		}

		this.animals = RESOURCE.animals;

		//
		this.partyWorld = PARTY;
		this.each = PARTY_ANIMALS;
		this.length = LENGTH;

		this.positions = [...POSITIONS];
		this.rotations = [...ROTATIONS];

		// this.positionsOrigin = [...POSITIONS];

		this.activeModels = this.partyWorld.activeMeshs;
		this.partsScale = this.conversionRate * PARTY_SCALE;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		this.physicMode = 0;
		this.activateMouseTrackBall = 1;
		this.activateRaycast = 0;

		//
		this.mouseFollowBall = {
			size : 1,
			radius : 1,
			mesh : null,
			body: null,
			power : 0,
			tween : {
				down : null,
				up : null
			}
		}

		//
		this.initPhysics();

	}

	mousedown(){
		this.mouseFollowBall.tween.down = gsap.to( this.mouseFollowBall, {
			duration : .1,
			power : this.phyOptions.ballPower,
		});
	}

	mouseup(){
		if( this.mouseFollowBall.tween.down ) this.mouseFollowBall.tween.down.kill();
		this.mouseFollowBall.power = 0;
	}

	reload(){

		if( ROUTE.current.type === 'notfound' ){

			this.physicMode = 1;

			//
			this.world.defaultContactMaterial.contactEquationStiffness = 20; // 接触緩和 default 1e7 (重力他の影響)

			//
			for (let i = 0; i < this.length; i++) {
				this.resetPhysicsByIndex(i);
			}

		} else {

			this.physicMode = 0;

			//
			this.world.defaultContactMaterial.contactEquationStiffness = 8; // 接触緩和 default 1e7 (重力他の影響)

			//
			for (let i = 0; i < this.length; i++) {
				this.resetPhysicsByIndex(i);
			}

		}
		//
		this.mouseBallResize();


		// switch( ROUTE.current.type ){
		// 	case 'notfound':

		// 		console.log('----------------------------------------');
		// 		console.log('reload [1]');
		// 		console.log('----------------------------------------');

		// 		//
		// 		// this.world.gravity.x = 0;
		// 		// this.world.gravity.y = -200;
		// 		// this.world.gravity.x = 0;

		// 		//
		// 		// for (let i = 0; i < this.positions.length; i++) {
		// 		// 	this.positions[i].x = this.plW    * 3 * Math.random() - this.plW * 1.5;
		// 		// 	this.positions[i].y = this.height * 3 * Math.random() + this.height * 1.5;
		// 		// }

		// 		//
		// 		this.physicMode = 1;
		// 		for (let i = 0; i < this.length; i++) {
		// 			this.resetPhysicsByIndex(i);
		// 		}

		// 	break;
		// 	default:

		// 		console.log('----------------------------------------');
		// 		console.log('reload [2]');
		// 		console.log('----------------------------------------');

		// 		//
		// 		// this.world.gravity.x = 0;
		// 		// this.world.gravity.y = 0;
		// 		// this.world.gravity.x = 0;

		// 		//
		// 		// for (let i = 0; i < this.positions.length; i++) {
		// 		// 	this.positions[i].x = this.positionsOrigin[i].x;
		// 		// 	this.positions[i].y = this.positionsOrigin[i].y;
		// 		// }

		// 		//
		// 		this.physicMode = 0;
		// 		for (let i = 0; i < this.length; i++) {
		// 			this.resetPhysicsByIndex(i);
		// 		}

		// 	break;
		// }

		// this.resizeTextArea();

	}

	initPhysics() {

		// this.raycaster = new THREE.Raycaster()

		this.grounds = [];
		this.bodies  = [];
		this.meshs   = [];
		this.pivots  = [];
		this.springs = [];
		this.models  = [];

		this.world = new CANNON.World();
		this.world.broadphase = new CANNON.NaiveBroadphase();

		// 重力の方向と強さ
		this.world.gravity.copy( new CANNON.Vec3(0, 0, 0) );

		this.world.solver.iterations = this.phyOptions.iterations; // 精度 default 10
		this.world.solver.tolerance = this.phyOptions.tolerance; // 寛容さ default 1e-7

		this.world.defaultContactMaterial.frictionEquationRelaxation = this.phyOptions.frictionER; // 摩擦緩和 default 3
		this.world.defaultContactMaterial.contactEquationRelaxation = this.phyOptions.contactER; // 接触緩和 default 3
		this.world.defaultContactMaterial.frictionEquationStiffness = this.phyOptions.frictionES; // 摩擦係数 default 1e7
		this.world.defaultContactMaterial.contactEquationStiffness = this.phyOptions.contactES; // 接触緩和 default 1e7 (重力他の影響)
		this.world.defaultContactMaterial.friction = this.phyOptions.friction; // 摩擦 default 0.3
		this.world.defaultContactMaterial.restitution = this.phyOptions.restitution; // 復旧 default 0.3

		//
		// this.init();
		this.createWalls();

		//
		this.createShape();

		//
		this.populate();

	}

	populate() {

		// reset old
		// this.clearMesh();


		// ===================================================
		// add object
		for (let i = 0; i < this.length; i++) {
			this.addMesh(i);
		}

		// ===================================================
		// mouse track
		if( this.activateMouseTrackBall ){
			this.createMouseFollowBall();
		}

		// this.mouseTracker = new MouseTracker();
		// this.mouseTracker.trackMousePos();
		// // ===================================================
		// // mouse
		// if( this.activateRaycast ){
		// 	this.mouseEvent();
		// }

	}

	// 平面の作成
	createPlane( position, rotation, key ) {

		// mesh
		const geometry = new THREE.PlaneBufferGeometry(1, 1);
		let material = null;
		let mesh = null;

		if( key === 'front' || key === 'back' ){
			// var material = new THREE.MeshBasicMaterial({
			// 	color: new THREE.Color('red'),
			// 	side: THREE.DoubleSide,
			// 	wireframe: true
			// });
		} else {
			material = new THREE.MeshBasicMaterial({
				color: COLOR_ORIGIN.brown.three,
				side: THREE.DoubleSide,
				// wireframe: true
			});
			mesh = new THREE.Mesh( geometry, material );
			mesh.position.copy(new THREE.Vector3(position.x, position.y, position.z));
			mesh.rotation.copy(new THREE.Euler(rotation.x, rotation.y, rotation.z));
			mesh.scale.x = this.plW*2;
			mesh.scale.y = this.plW*2;
		}

		// physics
		const body = new CANNON.Body({
			shape: new CANNON.Plane(),
			position: new CANNON.Vec3(position.x, position.y, position.z),
			quaternion: new CANNON.Quaternion().setFromEuler(rotation.x, rotation.y, rotation.z)
		});

		return { body : body, mesh : mesh };

	}

	// 4つのフラットパーティションの作成
	createWalls() {

		this.plW = getPxReScale(this.width)  * 3;
		this.plH = getPxReScale(this.height) * 2;
		this.plD = getPxReScale(this.width)  * 3;
		this.walls = {
			front : {
				mesh : null,body : null,
				position : {x : 0, y : 0, z : this.plD * 0, },
				rotation : {x : 0, y : getRad(-180), z : 0, }
			},
			back : {
				mesh : null, body : null,
				position : {x : 0, y : 0, z : -this.plD, },
				rotation : {x : 0, y : 0, z : 0, }
			},
			// bottom : {
			// 	mesh : null,body : null,
			// 	position : {x : 0, y : -this.plH/2, z : 0, },
			// 	rotation : {x : getRad(-90), y : 0, z : 0, }
			// },
			// top : {
			// 	mesh : null, body : null,
			// 	position : {x : 0, y : this.plH*2, z : 0, },
			// 	rotation : {x : getRad(90), y : 0, z : 0, }
			// },
			// left : {
			// 	mesh : null,body : null,
			// 	position : {x : -this.plW, y : 0, z : 0, },
			// 	rotation : {x : 0, y : getRad(90), z : getRad(-90), }
			// },
			// right : {
			// 	mesh : null, body : null,
			// 	position : {x : this.plW, y : 0, z : 0, },
			// 	rotation : {x : 0, y : getRad(-90), z : getRad(90), }
			// }
		}

		//
		const keys = Object.keys( this.walls );
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const v = this.walls[key];
			const plane = this.createPlane( v.position, v.rotation, key );
			v.body = plane.body;
			v.mesh = plane.mesh;
			if( plane.body ) this.world.addBody( v.body );
			if( plane.mesh ) this.partyWorld.scene.add( v.mesh );
		}

	}

	createShape() {

		Object.keys(this.each).forEach(key => {
			const s = this.width * this.partsScale * this.each[key].scale / this.rescale;
			const resize = (array) => array.map((value) => { return value * -s });
			let v = this.modelParts[key];
			let partsTypes = v.type;
			let partsSizes = resize(v.size);
			let partsPositions = resize(v.position);
			let n, m = [];
			for(let j = 0; j < partsTypes.length; j++){
					n = j * 3;
					m[j] = (partsTypes[j] === 'sphere')
						? new THREE.SphereBufferGeometry(partsSizes[n+0],10,10)
						: new THREE.BoxBufferGeometry(partsSizes[n+0], partsSizes[n+2], partsSizes[n+1]);
					m[j].translate(partsPositions[n+0], partsPositions[n+2], partsPositions[n+1]*-1);
			}
			v.geometry = BufferGeometryUtils.mergeBufferGeometries(m, true);
		});

		//
		// const test = new THREE.Mesh(
		// 	new THREE.BoxGeometry(1,1,1000),
		// 	new THREE['MeshPhongMaterial']({ color: '#000', name: 'bodyArea' })
		// );
		// this.partyWorld.scene.add( test );
		// test.position.set(0,0,0);
		// test.rotation.set(0,0,0);


		//
		// this.partyWorld.textArea.box = new THREE.Mesh(
		// 	new THREE.BoxGeometry(1,1,1),
		// 	new THREE.MeshBasicMaterial({
		// 		color: 0xff00ff,
		// 		wireframe: true
		// 	})
		// );
		// this.partyWorld.scene.add( this.partyWorld.textArea.box );

	}

	createBodyMesh(i) {

		// 確認用mesh
		this.meshs[i] = new THREE.Mesh(
			this.modelParts[ this.partyWorld.slider.now.key ].geometry,
			new THREE['MeshBasicMaterial']({
				color: '#000', name: 'bodyArea', transparent: true, wireframe: true,
			})
		);
		this.partyWorld.scene.add(this.meshs[i]);

	}

	createBody(i){

		// for (let i = 0; i < this.positions.length; i++) {
		// 	this.positions[i].x = this.plW    * 3 * Math.random() - this.plW * 1.5;
		// 	this.positions[i].y = this.height * 3 * Math.random() + this.height * 1.5;
		// }
		const px = this.positions[i].x;
		const py = this.positions[i].y;
		const pz = this.positions[i].z;
		// if( ROUTE.current.type === 'notfound' ){
		// 	px = this.plW    * 3 * Math.random() - this.plW * 1.5;
		// 	py = this.height * 3 * Math.random();
		// }

		//
		this.bodies[i] = new CANNON.Body({
			// material
			// type
			position: new CANNON.Vec3( px, py, pz ),
			quaternion: new CANNON.Quaternion().setFromEuler( this.rotations[i].x, this.rotations[i].y, this.rotations[i].z),
			mass: ( this.physicMode === 0 ) ? this.phyOptions.SpringMass : this.phyOptions.ConeTwistMass,
			linearDamping:  this.phyOptions.linearDamping,
			angularDamping: this.phyOptions.angularDamping,
			// allowSleep: true,        // true
			// sleepSpeedLimit: 0.1,    // 0.1
			// sleepTimeLimit: 1,       // 1
			// collisionFilterGroup: 1, // 1
			// collisionFilterMask: 1,  // 1
			// fixedRotation: true,     // false
			velocity: this.phyOptions.velocity,
			angularVelocity: this.phyOptions.angularVelocity,
		});

		//
		const s = this.width * this.partsScale / 2 * this.each[ this.partyWorld.slider.now.key ].scale / this.rescale;
		const resize = (array) => array.map((value) => { return value * s });
		let v = this.modelParts[ this.partyWorld.slider.now.key ];
		let partsTypes = v.type;
		let partsSizes = resize(v.size);
		let partsPositions = resize(v.position);
		let n, m = [], l = [];
		for(let j = 0; j < partsTypes.length; j++){
			n = j * 3;
			m[j] = new CANNON.Vec3(partsSizes[n+0], partsSizes[n+2], partsSizes[n+1]);
			l[j] = new CANNON.Vec3(partsPositions[n+0], partsPositions[n+2], partsPositions[n+1]*-1);
			if( partsTypes[j] === 'sphere' ){
				m[j] = partsSizes[n+0];
				this.bodies[i].addShape( new CANNON.Sphere( m[j] ), l[j] );
			} else {
				m[j] = new CANNON.Vec3(partsSizes[n+0], partsSizes[n+2], partsSizes[n+1]);
				this.bodies[i].addShape( new CANNON.Box(m[j]), l[j] );
			}
		}

		//====
		for (let _s = 0; _s < this.bodies[i].shapes.length; _s++) {
			const _v = this.bodies[i].shapes[_s];
			_v.origin = {};
			switch( _v.type ){
				case 1:
					// Sphere
					_v.origin = {
						radius : _v.radius
					};
				break;
				case 4:
					// Box
					_v.origin = {
						halfExtents : {
							x : _v.halfExtents.x,
							y : _v.halfExtents.y,
							z : _v.halfExtents.z
						}
					};
				break;
			}
		}

		this.world.addBody(this.bodies[i]);

	}

	showBodySetting() {

		this.bodies.map( el => el.angularDamping = this.phyOptions.angularDampingToggle);
		setTimeout(() => {
			this.bodies.map( el => el.angularDamping = this.phyOptions.angularDamping);
		}, ANIM.party.twist.show.s * 1000 )

	}

	createSpring(i) {

		let r = 0;

// =================================================
//  * A spring, connecting two bodies.
//  *
//  * @param {Vec3}  [options.worldAnchorA] Where to hook the spring to body A, in world coordinates.
//  * @param {Vec3}  [options.worldAnchorB]
//  * @param {Vec3}  [options.localAnchorA] Where to hook the spring to body A, in local body coordinates.
//  * @param {Vec3}  [options.localAnchorB]
// =================================================

		//
		switch( this.physicMode ){
			case 0:
				// Spring
				this.maxForce = 0;
				this.springs[i] = new CANNON.Spring(this.bodies[i], this.pivots[i],{
					// bodyA: this.bodies[i],
					// bodyB: this.bodies[i],
					localAnchorA: new CANNON.Vec3( 0, 0, 0 ),
					// localAnchorA: new CANNON.Vec3( 0, 0, this.bodies[i].initPosition.z ),
					// localAnchorB: new CANNON.Vec3( 0, 0, 0 ),
					// localAnchorA: new CANNON.Vec3( this.bodies[i].initPosition.x, this.bodies[i].initPosition.y, 0 ),
					localAnchorB: new CANNON.Vec3( this.bodies[i].initPosition.x, this.bodies[i].initPosition.y, 0 ),
					restLength : this.phyOptions.Spring.restLength,
					stiffness  : this.phyOptions.Spring.stiffness,
					damping    : this.phyOptions.Spring.damping,
				});
			break;
			case 1:
				// Cone Twist
				this.maxForce = getPxReScale(10);
				this.pivots[i].position.z = this.positions[i].z;
				r = getPxReScale( 100 );
				this.springs[i] = new CANNON.ConeTwistConstraint( this.bodies[i], this.pivots[i], {
					pivotA: new CANNON.Vec3( r, r, 0 ),
					pivotB: new CANNON.Vec3( r*-1, 0, r ),
					axisA: CANNON.Vec3.UNIT_X,
					axisB: CANNON.Vec3.UNIT_X,
					angle: 2,
					twistAngle: 0,
					maxForce: this.maxForce,
				})
				this.world.addConstraint(this.springs[i])
			break;
			default:
				this.maxForce = 0;
				this.springs[i] = 1;
			break;
		}

	}


	addMesh(i) {

		// ===========================================
		// Create a static sphere
		this.pivots[i] = new CANNON.Body({
			mass: 0,
			shape: new CANNON.Sphere(0.1),
			position: new CANNON.Vec3(0,0,this.positions[i].z),
			// position: new CANNON.Vec3(this.positions[i].x, this.positions[i].y, this.positions[i].z),
		});
		this.world.addBody(this.pivots[i]);

		// 位置確認用mesh
		// const sphere = new THREE.Mesh(
		// 	new THREE.SphereGeometry(10,10,10),
		// 	new THREE.MeshBasicMaterial({ color: 0xff00ff, wireframe: true })
		// );
		// sphere.position.copy(this.pivots[i].position);
		// this.partyWorld.scene.add( sphere );

		// ===========================================
		// Create a box body
		this.createBody(i);
		if( debugBodyPos ){ // body位置確認
			this.createBodyMesh(i);
		}

		// ===========================================
		// Spring
		this.createSpring(i);
	}

	//
	// switch( ACTIVE ) {
	// 	this.activeModels = ACTIVE;
	// 	this.resetPhysics();
	// }
	// //
	// resetPhysics(){
	// 	//
	// 	for (let i = 0; i < this.bodies.length; i++) {
	// 		if( this.bodies[i] ){
	// 			this.world.removeBody( this.bodies[i] );
	// 			this.bodies[i] = null;
	// 		}
	// 	}
	// 	for (let i = 0; i < this.length; i++) {
	// 		this.createBody(i);
	// 		this.world.addBody(this.bodies[i]);
	// 		if( this.meshs[i] ){
	// 			this.meshs[i].geometry = this.modelParts[ this.partyWorld.slider.now.key ].geometry;
	// 		}
	// 	}
	// }

	switchByIndex( v, index ) {
		this.activeModels[index] = v;
		this.resetPhysicsByIndex( index );
	}

	resetPhysicsByIndex( index ){

		if( this.bodies[ index ] ){
			this.world.removeBody( this.bodies[ index ] );
			this.bodies[ index ] = null;
			this.createBody( index );
			this.world.addBody(this.bodies[ index ]);
			if( this.meshs[ index ] ){
				this.meshs[ index ].geometry = this.modelParts[ this.partyWorld.slider.now.key ].geometry;
			}
		}
		if( Boolean(this.springs[ index ] && this.bodies[ index ]) ){
			this.world.removeConstraint( this.springs[ index ] );
			this.springs[ index ] = null;
			this.createSpring( index );
		}
	}

	// マウスを追跡するボールの作成
	createMouseFollowBall() {

		//=========================
		// MESH
		//=========================
		if( debugBallPos ){
			const geometry = new THREE.SphereBufferGeometry(1, 32, 16);
			const material = new THREE.MeshLambertMaterial({
				wireframe: true,
			});
			this.mouseFollowBall.mesh = new THREE.Mesh( geometry, material );
			this.partyWorld.scene.add( this.mouseFollowBall.mesh );
		}

		//=========================
		// SIZE
		//=========================
		this.mouseBallResize();

		//=========================
		// BODY
		//=========================
		this.mouseFollowBall.body = new CANNON.Body({
			mass: 5,
			shape: new CANNON.Sphere( this.mouseFollowBall.radius ),
			type: CANNON.Body.KINEMATIC
		});
		this.world.addBody( this.mouseFollowBall.body );

	}
	mouseBallResize() {

		const s = ( ROUTE.current.type === 'notfound' || ROUTE.current.type === 'subpage' || ROUTE.current.type === 'ai' ) ? 0.5 : 1 ;
		if ( !DETECT.device.any ) {
			this.mouseFollowBall.radius = this.phyOptions.ballSize * s;
		} else {
			this.mouseFollowBall.radius = this.phyOptions.ballSizeMobile * s;
		}
		if( debugBallPos ){
			this.mouseFollowBall.mesh.scale.x = this.mouseFollowBall.radius;
			this.mouseFollowBall.mesh.scale.y = this.mouseFollowBall.radius;
			this.mouseFollowBall.mesh.scale.z = this.mouseFollowBall.radius;
		}

	}
	// 大型ボールトラッキングマウス
	mouseBallFollow() {

		const pos = new THREE.Vector3( x, y, -this.mouseFollowBall.radius );
		this.mouseFollowBall.body.position.copy( pos );

		let x = 0;
		let y = 0;

		if( DETECT.device.any ){
			x = getPxReScale( touchEvent.start.cx * this.width * 4 );
			y = getPxReScale( ( -touchEvent.start.cy * this.height * 4 ) + stylePageScroll.body.y);
			this.mouseFollowBall.body.position.x = x;
			this.mouseFollowBall.body.position.y = y;
			this.mouseFollowBall.body.position.z = this.phyOptions.ballPosZMobile;
		} else {
			x = getPxReScale( mouseEvent.anims.cursor.cx * this.width * 3 );
			y = getPxReScale( ( -mouseEvent.anims.cursor.cy * this.height * 3 ) + stylePageScroll.body.y);
			this.mouseFollowBall.body.position.x = x;
			this.mouseFollowBall.body.position.y = y;
			this.mouseFollowBall.body.position.z = this.phyOptions.ballPosZ;
		}

		//
		let mouseScale = this.mouseFollowBall.radius * this.mouseFollowBall.power + getClamp( mouseEvent.anims.cursor.speed.xy*5, 0, this.mouseFollowBall.radius );
		if( this.physicMode === 1 ){
			mouseScale = this.mouseFollowBall.radius * this.mouseFollowBall.power + getClamp( mouseEvent.anims.cursor.speed.xy*5, this.mouseFollowBall.radius, this.mouseFollowBall.radius );
		}
		this.mouseFollowBall.body.shapes[0].radius = mouseScale;

		//
		if( debugBallPos && this.mouseFollowBall.mesh ){
			// console.log(this.mouseFollowBall);
			this.mouseFollowBall.mesh.scale.x = mouseScale;
			this.mouseFollowBall.mesh.scale.y = mouseScale;
			this.mouseFollowBall.mesh.scale.z = mouseScale;
			this.mouseFollowBall.mesh.position.copy(this.mouseFollowBall.body.position);
			this.mouseFollowBall.mesh.quaternion.copy(this.mouseFollowBall.body.quaternion);
		}

	}

	//----------------------------------
	// CLEAR
	//----------------------------------
	// clearMesh() {
	// 	for (let i = 0; i < this.bodies.length; i++) {
	// 		this.partyWorld.scene.remove(this.bodies[i]);
	// 	}
	// 	this.bodies = [];
	// 	for (let i = 0; i < this.meshs.length; i++) {
	// 		this.partyWorld.scene.remove(this.meshs[i]);
	// 	}
	// 	this.meshs = [];
	// 	for (let i = 0; i < this.grounds.length; i++) {
	// 		this.partyWorld.scene.remove(this.grounds[i]);
	// 	}
	// 	this.grounds = [];
	// 	for (let i = 0; i < this.models.length; i++) {
	// 		this.partyWorld.scene.remove(this.models[i]);
	// 	}
	// 	this.models = [];
	// }

	/* ===================================================
	LOOP
	=================================================== */
	updatePhysics() {

		if (this.world == null) return;

		this.world.step(1 / 60);

		if( this.activateMouseTrackBall ) this.mouseBallFollow();

		//
		for (let i = 0; i < this.bodies.length; i++) {

			//
			const body   = this.bodies[i];
			const mesh   = this.meshs[i];
			const spring = this.springs[i];
			const pivots = this.pivots[i];
			//
			const model = this.activeModels[i];
			const modelMesh = model ? this.activeModels[i].mesh : null;

			// force
			if( this.physicMode === 0 && spring ) {
				spring.applyForce( new CANNON.Vec3(1, 1, 1), new CANNON.Vec3(1, 1, 1) );
			}
			//
			if( this.physicMode === 1 && pivots ){
				pivots.position.x = this.mouseFollowBall.body.position.x;
				pivots.position.y = this.mouseFollowBall.body.position.y;
			} else {
				pivots.position.x = 0;
				pivots.position.y = 0;
			}

			if( model ){

				// ループ
				if( ROUTE.current.type === 'notfound' && body.position.y < -this.height * 3 ){
					body.position.y = this.height * 3;
					body.position.x = this.width * 3 * Math.random() - this.width * 1.5;
				}

				// 物理演算された結果をmodelにコピー
				if( body ){

					modelMesh.position.copy(body.position);
					modelMesh.quaternion.copy(body.quaternion);

					if( debugBodyPos ){ // body位置確認
						mesh.position.copy(body.position);
						mesh.quaternion.copy(body.quaternion);
					}

					// SCALE
					for (let _s = 0; _s < body.shapes.length; _s++) {
						const _v = body.shapes[_s];
						switch( _v.type ){
							case 1:
								_v.radius = _v.origin.radius * model.origin.force;
							break;
							case 4:
								_v.halfExtents.x = _v.origin.halfExtents.x * model.origin.force;
								_v.halfExtents.y = _v.origin.halfExtents.y * model.origin.force;
								_v.halfExtents.z = _v.origin.halfExtents.z * model.origin.force;
							break;
						}
					}

					// SPRING
					if( spring && this.maxForce ){
						spring.equationX.maxForce =  this.maxForce * model.origin.force;
						spring.equationX.minForce = -this.maxForce * model.origin.force;
						spring.equationY.maxForce =  this.maxForce * model.origin.force;
						spring.equationY.minForce = -this.maxForce * model.origin.force;
						spring.equationZ.maxForce =  this.maxForce * model.origin.force;
						spring.equationZ.minForce = -this.maxForce * model.origin.force;
						for (let _t = 0; _t < spring.equations.length; _t++) {
							spring.equations[_t].maxForce =  this.maxForce * model.origin.force;
							spring.equations[_t].minForce = -this.maxForce * model.origin.force;
						}
					}

				}
			}


		}
		//

	}

	// /* ===================================================
	// TEXT
	// =================================================== */
	// initText(){

	// 	//
	// 	this.textBox = {
	// 		shape : null,
	// 		body : null,
	// 		mesh : null,
	// 		position : {
	// 			x : 0,
	// 			y : 0,
	// 			z : -__WH__/2,
	// 		}
	// 	}

	// 	//
	// 	this.textBox.shape = new CANNON.Box( new CANNON.Vec3( 1, 1, 1) );
	// 	this.textBox.body = new CANNON.Body({
	// 		mass  : 1,
	// 		shape : this.textBox.shape,
	// 		type  : CANNON.Body.KINEMATIC
	// 	});
	// 	this.textBox.body.addShape( this.textBox.shape );
	// 	this.textBox.body.position.set( this.textBox.position.x, this.textBox.position.y, this.textBox.position.z );
	// 	this.world.addBody(	this.textBox.body );

	// 	//
	// 	// const geometry = new THREE.BoxGeometry( 1, 1, 1 );
	// 	// const material = new THREE.MeshBasicMaterial({
	// 	// 	color     : '#ff0000',
	// 	// 	wireframe : true
	// 	// })
	// 	// this.textBox.mesh = new THREE.Mesh( geometry, material );
	// 	// this.textBox.mesh.position.x = this.textBox.position.x;
	// 	// this.textBox.mesh.position.y = this.textBox.position.y;
	// 	// this.textBox.mesh.position.z = this.textBox.position.z;
	// 	// this.partyWorld.scene.add( this.textBox.mesh );

	// }

	// resizeTextArea(){

	// 	const v = this.textBox;

	// 	//
	// 	let scale = 0;
	// 	let visible = false;
	// 	if( ROUTE.current.type === 'notfound' && !DETECT.device.any ){
	// 		scale = getPxReScale(900);
	// 		visible = true;
	// 	}

	// 	//
	// 	// if( v.mesh ){ v.mesh.visible = visible; v.mesh.scale.x = scale; v.mesh.scale.y = scale; v.mesh.scale.z = scale; }
	// 	if( v.body ){
	// 		v.body.position.x = v.position.x;
	// 		v.body.position.y = v.position.y;
	// 		v.body.position.z = v.position.z;
	// 		for (let i = 0; i < v.body.shapes.length; i++) {
	// 			v.body.shapes[i].halfExtents.x = scale * 8;
	// 			v.body.shapes[i].halfExtents.y = scale * 8;
	// 			v.body.shapes[i].halfExtents.z = scale * 8;
	// 		}
	// 	}

	// }

	// updateTextArea(){
	// 	// const v = this.textBox;
	// 	// if( v.mesh ){
	// 	// 	v.mesh.position.copy( v.body.position );
	// 	// 	v.mesh.quaternion.copy( v.body.quaternion );
	// 	// }
	// }

}

