/*
██████╗  █████╗  ██████╗██╗  ██╗ ██████╗ ██╗
██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔════╝ ██║
██████╔╝███████║██║     █████╔╝ ██║  ███╗██║
██╔══██╗██╔══██║██║     ██╔═██╗ ██║   ██║██║
██████╔╝██║  ██║╚██████╗██║  ██╗╚██████╔╝███████╗
╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝ ground
*/


import { SCENE_GROUND } from '/assets/js/webgl/class/c-ground.js';
import ground2D from '~/assets/js/shader/frag/ground2D.frag';

BACKGL.scenes.gound = {

	visible : false,
	ready : false,

	setZIndex( visible ){

		if( visible ){
			this.mesh.position.z =  10;
		} else {
			this.mesh.position.z = -10;
		}

	},

	// uniforms : {
	// 	blurPower : {
	// 		x : 5,
	// 		y : 5,
	// 	},
	// 	stepPower : 0.48,
	// 	fogColor  : '#ebafcc',
	// },

	onLoaded(){

		this.world = new SCENE_GROUND( false, true );
		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : ground2D,
			uniforms: {
				borderRadius: { value : 0.05 },
				LT: { value : 1 },
				RT: { value : 1 },
				RB: { value : 0 },
				LB: { value : 0 },
				resolution: { value: { x: 0, y: 0 } },
				scale: { value: { x: 1, y: 1 } },
				tColor : { value: COLOR_ORIGIN.black.three },
				tDiffuse : { value: this.world.fbo.texture }
			},
			transparent: true,
		});
		material.alphaTest = 0.5;


		//
		this.mesh = new THREE.Mesh(geometry, material);
		switch( ROUTE.current.type ){
			case 'single':
			case 'ai':
			case 'subpage':
			case 'notfound':
				this.mesh.visible = false;		
			break;
			default:
				this.mesh.visible = true;
			break;
		}

		this.mesh.position.z = 0;
		BACKGL.sceneFrontSide.add( this.mesh );

		//
		this.onResize();
		this.ready = true;

		// //
		// this.visible = true;
		// this.mesh.visible = true;
		// this.mesh.material.visible = true;

	},

	onResize(){

		//
		this.mesh.scale.x = window.innerWidth;
		this.mesh.scale.y = window.innerHeight;
		this.mesh.material.uniforms.resolution.value.x = window.innerWidth;
		this.mesh.material.uniforms.resolution.value.y = window.innerHeight;
		this.mesh.material.uniforms.borderRadius.value = __WW__/this.mesh.scale.x * BORDER_RADIUS;
		this.world.resize();

	},

	onIniview( dom, visibleAny = true ){
		if( dom ){

			//
			this.visible = visibleAny;
			this.mesh.visible = visibleAny;
			this.mesh.material.visible = visibleAny;

			//
			if( !RESOURCE.isloaded ){
				// 読込中に一度は表示したい
				this.world.inview( true, dom.name, visibleAny );
				this.world.useSetting( dom.name );
			} else {
				this.world.inview( dom.visible, dom.name, visibleAny );
				if( dom.visible ){
					this.world.useSetting( dom.name );
				}
			}

			//
			this.setZIndex( visibleAny );

		}
	},

	currentElement : {
		rect : { top:0, left:0 },
	},

	onReset(){
		this.visible = false;
		this.mesh.visible = false;
		if( this.world ) this.world.reset();
	},

	onRender(){

		BACKGL.renderer.bg.render.setRenderTarget( this.world.fbo );
		BACKGL.renderer.bg.render.render( this.world.scene, this.world.camera );

	},

	onScroll(){

		this.mesh.position.y = -this.currentElement.rect.top + stylePageScroll.body.y;
		this.world.render( this.mesh.position.y/__WH__ );

	}

}










