/*
██████╗  ██████╗ ███╗   ███╗
██╔══██╗██╔═══██╗████╗ ████║
██║  ██║██║   ██║██╔████╔██║
██║  ██║██║   ██║██║╚██╔╝██║
██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚═════╝  ╚═════╝ ╚═╝     ╚═╝ functions
*/


// getPxScale(200) 1440基準での200pxを返す
window.getPxScale = function(num){
	const px = 1/1440 * window.innerWidth * num;
	return px;
}

window.getPxReScale = function(num){
	const px = (1/1440 * window.innerWidth * num) / RESCALE.r;
	return px;
}

//
window.getRad = deg => deg * Math.PI / 180;

//
window.getParam = function(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}


// //
// window.getParentClass = function (baseElem,parentClass) {
// 	// baseElem    起点となる要素
// 	// parentClass 返される要素
//     const parents = [];
//     const parentElem = function (elem) {
//         if (elem.parentNode) {
//             parents.push(elem.parentNode);
//             parentElem(elem.parentNode);
//         }
//     };
//     parents['prevObject'] = baseElem;
//     parentElem(baseElem);
//     for (let i = 0; i < parents.length; i++) {
//     	const tag = parents[i].tagName;
//     	if( tag ){
// 	    	if( parents[i].classList.contains( parentClass ) ){
// 	    		return parents[i];
// 	    	}
//     	}
//     }
//     return false;
// };


//
window.killTweens = function( array ){
	for (let i = 0; i < array.length; i++) {
		if( array[i] ) array[i].kill();
	}
	array = [];
}

//
window.onPlayVideo = function( el ){
	if( el ){
		const playPromise = el.play();
		// el.playbackRate = 1.2;
		if (playPromise !== undefined) {
			playPromise.then(_ => {
			})
			.catch(error => {
			});
		}
	}
}


window.getRandomInt = function(max) {
	return Math.floor(Math.random() * Math.floor(max));
}

window.getClamp = function(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

window.getThemeColorName = function(name){
	switch( name ){
		case 'index':
		case 'cases-slug':
		case '404':
			return 'white';
		break;
		case 'cases':
		case 'archive':
		case 'about':
		case 'contact':
			return 'black';
		break;
		default:
			return 'white';
		break;
	}
};

window.getWheelDistXY = function( e ){

	const x = e.deltaX ? -(e.deltaX) : e.wheelDeltaX ? e.wheelDeltaX : -(e.detail);
	const y = e.deltaY ? -(e.deltaY) : e.wheelDeltaY ? e.wheelDeltaY : -(e.detail);
	const d = e.detail;

	if( d ) {

		if ( y ){
			let _x = x / d / 40 * d > 0 ? 1 : -1;
			let _y = y / d / 40 * d > 0 ? 1 : -1;
			return { x : _x , y : _y }
		} else {
			let _x = 0;
			let _y = -d / 3;
			return { x : _x , y : _y }
		}

	} else {

		let _x = x / 120;
		let _y = y / 120;
		return { x : _x , y : _y }

	}

}


window.getWheelDistY = function( e ){
	const w = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.wheelDelta : -(e.detail);
	const d = e.detail;
	if (d) {
		if (w) return w / d / 40 * d > 0 ? 1 : -1
		else return -d / 3
	} else return w / 120
}





/*
██╗███╗   ██╗██╗   ██╗██╗███████╗██╗    ██╗
██║████╗  ██║██║   ██║██║██╔════╝██║    ██║
██║██╔██╗ ██║██║   ██║██║█████╗  ██║ █╗ ██║
██║██║╚██╗██║╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
██║██║ ╚████║ ╚████╔╝ ██║███████╗╚███╔███╔╝
╚═╝╚═╝  ╚═══╝  ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝
*/
window.styleInview = {

	body : { el : null },
	$inview : null,
	$inviewChild : null,

	onInit( elem ){

		this.onReset();

		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelectorAll('.site-content')[0]; }

		//===
		this.$inview  = document.querySelectorAll('.js-iv');
		this.$inviewChild  = document.querySelectorAll('.js-iv-c > *');
		this.targets = [ ...this.$inview, ...this.$inviewChild ];

		//===
		if( this.targets.length ){
			this.options = {
				threshold: 0
			};
			this.observer = new IntersectionObserver( this.listerner, this.options );
			this.targets.forEach( (e,i) => {
				this.observer.observe(e);
			});
		}

	},

	onReset : function(){
		const _this = this;
		if( this.observer ){
			this.targets.forEach( (e,i) => {
				_this.observer.disconnect(e);
			})
			this.targets  = null;
			this.observer = null;
		}
		this.$inview = null;
		this.$inviewChild = null;
	},

	listerner (changes) {

		const _this = this;
		changes.forEach( (e,i) => {
			const el = e.target;
			const isIntersecting = e.isIntersecting;
			if ( isIntersecting ) {
				el.dataset.shown   = 1;
				el.dataset.visible = 1;
			} else {
				el.dataset.visible = 0;
			}
		});

	},

}





/*
███████╗████████╗██╗   ██╗██╗     ███████╗    ██████╗  ██████╗ ███╗   ███╗
██╔════╝╚══██╔══╝╚██╗ ██╔╝██║     ██╔════╝    ██╔══██╗██╔═══██╗████╗ ████║
███████╗   ██║    ╚████╔╝ ██║     █████╗      ██║  ██║██║   ██║██╔████╔██║
╚════██║   ██║     ╚██╔╝  ██║     ██╔══╝      ██║  ██║██║   ██║██║╚██╔╝██║
███████║   ██║      ██║   ███████╗███████╗    ██████╔╝╚██████╔╝██║ ╚═╝ ██║
╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚══════╝    ╚═════╝  ╚═════╝ ╚═╝     ╚═╝
*/
window.styleDom = {

	body : {
		el   : null,
	},

	// once(){
	// 	this.$bg = document.querySelector('.Sw-bg');
	// },

	onReset(){
		this.body.el   = null;
		// this.$wh       = null;
		// this.$mh       = null;
		// this.$whMb     = null;
		// this.$mhMb     = null;
		// this.$delayY   = null;
		this.$ratioByW = null;
		this.$ratioByH = null;
	},

	onInit( elem = document ){

		this.onReset();

		//
		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelector('.site-content'); }

		//
		styleMarquee.onInit( elem );

		//
		this.$ratioByW = this.body.el.querySelectorAll('.js-ratio-by-w');
		this.$ratioByH = this.body.el.querySelectorAll('.js-ratio-by-h');

		//
		this.onResize();

	},

	onResize(){

		// //
		// for (let i = 0; i < this.$wh.length; i++) {
		// 	this.$wh[i].style.height = window.innerHeight + 'px';
		// }

		// //
		// for (let i = 0; i < this.$mh.length; i++) {
		// 	this.$mh[i].style.minHeight = window.innerHeight + 'px';
		// }

		//
		// if( DETECT.device.mobile ){
		// 	for (let i = 0; i < this.$whMb.length; i++) {
		// 		this.$whMb[i].style.height = window.innerHeight + 'px';
		// 	}
		// 	for (let i = 0; i < this.$mhMb.length; i++) {
		// 		this.$mhMb[i].style.minHeight = window.innerHeight + 'px';
		// 	}
		// }

		//
		// this.$bg.style.top = window.innerHeight + 'px';

		//
		this.$ratioByW.forEach( (el,i) =>{
			const w = Number(el.dataset.w);
			const h = Number(el.dataset.h);
			const ratio = h/w;
			const rect  = el.getBoundingClientRect();
			el.style.height = rect.width * ratio + 'px';
		});

		//
		this.$ratioByH.forEach( (el,i) =>{
			const w = Number(el.dataset.w);
			const h = Number(el.dataset.h);
			const ratio = w/h;
			const rect = el.getBoundingClientRect();
			el.style.width = rect.height * ratio + 'px';
		});

		// //
		// this.$mq.forEach( (el,i) =>{
		// 	const mq = Number( el.dataset.mqLength );
		// 	const rect = el.getBoundingClientRect();
		// 	const r = (rect.width/window.innerWidth);
		// 	const s = 8 * r * 3/mq;
		// 	el.style.animationDuration = s + 's';
		// });

	}

}
// styleDom.once();



window.styleMarquee = {

	body : {
		el   : null,
	},

	ready : false,

	speed : 1,

	elems : [],

	once(){
		this.speed = getPxScale(1);
	},

	onReset(){
		this.body.el   = null;
		this.$mq = null;
		this.elems = [];
	},

	onInit( elem = document ){

		this.onReset();
		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelector('.site-content'); }
		this.$mq = this.body.el.querySelectorAll('.mq');


		//
		for (let i = 0; i < this.$mq.length; i++) {
			const wrap = this.$mq[i];
			const ul   = this.$mq[i].querySelector('.mq-ul');
			const rect = ul.getBoundingClientRect();

			//
			const li = this.$mq[i].querySelectorAll('.mq-t');

			//
			const n = Number( wrap.dataset.mqLength );
			this.elems.push({
				n    : n,
				wrap : wrap,
				ul   : {
					el : ul,
					rect : rect
				},
				li : {
					el : li,
				},
				x : 0
			});
			// ul.style.transform = 'translate3d('+ rect.width/n +'px,0px,0px)';
		}

		//
		if( this.elems.length && !IS_REDUCED ){
			this.ready = true;
			// this.onResize();
		}

	},

	// onResize(){
	// 	for (let i = 0; i < this.elems.length; i++) {

	// 		//============
	// 		// UL
	// 		//============
	// 		const v = this.elems[i];
	// 		v.ul.rect = v.ul.el.getBoundingClientRect();

	// 		//============
	// 		// LI
	// 		//============
	// 		const l = v.li.el;
	// 		for (var n = 0; n < l.length; n++) {
	// 			const li    = l[n];
	// 			const inner = li.querySelector('.pivot');
	// 			const rect  = inner.getBoundingClientRect();
	// 			li.style.height = rect.height + 'px';
	// 			li.style.width  = rect.width + 'px';
	// 		}

	// 	}
	// },

	onUpdate(){

		for (let i = 0; i < this.elems.length; i++) {
			const v = this.elems[i];
			const max = v.ul.rect.width/v.n - this.speed;
			const visible = Number(v.wrap.dataset.visible);
			if( visible ){
				v.x = Math.abs( v.x ) <= max ? v.x -= this.speed : 0;
				if( i%2 === 0 ){
					v.ul.el.style.transform = 'translate3d('+ v.x +'px,0px,0px)';
				} else {
					v.ul.el.style.transform = 'translate3d('+ ( (v.x*-1) - (v.ul.rect.width/v.n) ) +'px,0px,0px)';
				}
			}
		}

	}

}





















/*
 ██████╗███████╗███████╗
██╔════╝██╔════╝██╔════╝
██║     ███████╗███████╗
██║     ╚════██║╚════██║
╚██████╗███████║███████║
 ╚═════╝╚══════╝╚══════╝
*/
// window.cssAnimation = {

// 	events : [],

// 	onInit(){

// 		//this.removeEvents();
// 		this.$css = document.querySelectorAll('.js-c');
// 		this.addEvents();

// 	},

// 	addEvents(){

// 		this.events = [];

// 		this.$css.forEach( (el,i) =>{

// 			const start = function(e){
// 				el.dataset.propertyName = e.propertyName;
// 				el.dataset.anim = 1;
// 			};
// 			const end = function(e){
// 				el.dataset.propertyName = e.propertyName;
// 				el.dataset.anim = 0;
// 			};

// 			el.addEventListener('transitionstart', start );
// 			el.addEventListener('transitionend', end );
// 			const events = {
// 				el : el,
// 				start : {
// 					func : start,
// 				},
// 				end : {
// 					func : end,
// 				}
// 			}
// 			this.events.push( events );

// 		});

// 	},

// 	onReset(){
// 		for (let i = 0; i < this.events.length; i++) {
// 			const v = this.events[i];
// 			v.el.removeEventListener( 'transitionstart', v.start.func );
// 			v.el.removeEventListener( 'transitionend', v.end.func );
// 		}
// 		this.events = [];
// 	},
// }







/*
 ██████╗ ███████╗████████╗    ██╗███╗   ███╗ █████╗  ██████╗ ███████╗
██╔════╝ ██╔════╝╚══██╔══╝    ██║████╗ ████║██╔══██╗██╔════╝ ██╔════╝
██║  ███╗█████╗     ██║       ██║██╔████╔██║███████║██║  ███╗█████╗
██║   ██║██╔══╝     ██║       ██║██║╚██╔╝██║██╔══██║██║   ██║██╔══╝
╚██████╔╝███████╗   ██║       ██║██║ ╚═╝ ██║██║  ██║╚██████╔╝███████╗
 ╚═════╝ ╚══════╝   ╚═╝       ╚═╝╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝
*/
window.getImageSrc = function( d1x, d2x, mob, webp ){

	let src = d1x;

	if( DETECT.retina ){
		src = d2x;
	}

	if( DETECT.device.tablet ){
		src = d2x;
	}

	if( DETECT.device.mobile ){
		src = mob;
	}

	//
	if( webp ){
		src = src + '.webp';
	}

	let unique = 0;
	if( DETECT.device.any ){
		const date = new Date();
		unique = Math.floor( date.getTime() / 1000 );
	}

	return src;

};


window.getVideoSrc = function( dataset ){
	let src = dataset.src;
	if( !dataset.src ){
		const d2x = dataset.d2x;
		const d1x = dataset.d1x;
		const mob = dataset.mob;
		if( d2x && d1x && mob ){
			if( DETECT.retina ){
				src = d2x;
			} else {
				src = d1x;
			}
			if( DETECT.device.tablet ){
				src = d2x;
			}
			if( DETECT.device.mobile ){
				src = mob;
			}
		}
	}
	return src;
}



/*
██████╗  ██████╗     ██╗███╗   ███╗ ██████╗
██╔══██╗██╔════╝     ██║████╗ ████║██╔════╝
██████╔╝██║  ███╗    ██║██╔████╔██║██║  ███╗
██╔══██╗██║   ██║    ██║██║╚██╔╝██║██║   ██║
██████╔╝╚██████╔╝    ██║██║ ╚═╝ ██║╚██████╔╝
╚═════╝  ╚═════╝     ╚═╝╚═╝     ╚═╝ ╚═════╝
*/

window.styleBgImg = {

	ready : false,

	body : { el : null },

	timer : [],

	targets : [],
	$img    : [],
	$video  : [],
	$inview : [],

	//
	anims   : [],

	//
	onInit( elem = document ){

		//===
		if( elem ){ this.body.el = elem } else { this.body.el = document.querySelector('.Pc'); }

		this.onReset();

		//===
		this.$img   = this.body.el.querySelectorAll('.js-bg-img');
		this.$video = this.body.el.querySelectorAll('.js-bg-video');

		//===
		if( DETECT.device.any ){
			this.$inview = this.body.el.querySelectorAll('.js-bg-img-inview,.js-bg-video-inview');
			this.targets = [ ...this.$inview ];
		} else if( IS_REDUCED ){
			this.$inview = this.body.el.querySelectorAll('.js-bg-img-inview.use-da,.js-bg-video-inview.use-da');
			this.targets = [ ...this.$inview ];
		} else {
			this.$inview = this.body.el.querySelectorAll('.js-bg-img-inview.use-dp,.js-bg-video-inview.use-dp');
			this.targets = [ ...this.$inview ];
		}

		this.all = [ ...this.$img, ...this.$video, ...this.$inview ];

		//===
		if( this.targets.length ){
			this.options = {
				threshold: 0
			};
			this.observer = new IntersectionObserver( this.listerner, this.options );
			this.targets.forEach( (e,i) => {
				this.observer.observe(e);
			});
		}

		// if( this.anims.length ){
		// 	this.ready = true;
		// } else {
		// 	this.ready = false;
		// }

		this.onLoadImages();

	},

	onReset : function(){

		//
		const _this = this;

		this.ready = false;

		//
		for (let i = 0; i < this.timer.length; i++) {
			clearTimeout( this.timer[i] );
		}
		this.timer = [];

		//
		if( this.observer ){
			this.targets.forEach( (e,i) => {
				_this.observer.disconnect(e);
			})
			this.targets  = null;
			this.observer = null;
		}

		//
		this.timer = [];
		this.$img = [];
		this.$video = [];
		this.$inview = [];

		//
		this.anims = [];

	},

	onLoadImages(){
		const _this = this;
		this.all.forEach( ( el, index ) =>{
			const wait = 100*index;
			const timer = setTimeout(function(){
				if( !el.classList.contains('is-img-load-start') && ( el.classList.contains('only-desktop') && !DETECT.device.any ) ){
					if( el.classList.contains('js-bg-img') ){
						onLoadImage( el, true, false );
					} else if( el.classList.contains('js-bg-video') ){
						onLoadVideo( el );
					}
				}
			}, wait );
			_this.timer.push( timer );
		});
	},

	listerner (changes) {
		const _this = this;
		changes.forEach( (e,i) => {
			const el = e.target;
			const isIntersecting = e.isIntersecting;
			if ( isIntersecting ) {
				if( el.classList.contains('js-bg-img-inview') ){
					if( parseInt(el.dataset.shown) != 1 && !el.classList.contains('is-img-load-start') ){
						onLoadImage( el, true, true );
					}
				} else if( el.classList.contains('js-bg-video-inview') ){
					if( parseInt(el.dataset.shown) != 1  ){
						onCreateVideo( el );
					}
				}
				el.dataset.shown   = 1;
				el.dataset.visible = 1;
			} else {
				el.dataset.visible = 0;
			}
		});
	},

}


//
window.onLoadVideo = function(el){
	const video = el.querySelector('video');
	video.preload = "metadata";
	video.muted = true;
}

//
window.onCreateVideo = function(el){
	if( el.querySelector('video') ){

		const video = el.querySelector('video');
		video.muted = true;
		video.loop = true;
		if( !IS_REDUCED ) onPlayVideo( video );
		el.classList.add('is-video-loaded');

	} else {

		const img = el.querySelector('.img');

		// DESKTOPサファリのみ動画ではなく静止画表示
		if( !DETECT.device.any && DETECT.browser === 'safari' || IS_REDUCED ){

			//
			el.classList.add('js-bg-img-inview');
			const poster = new Image();
			// poster.crossOrigin = "anonymous";
			poster.onload = function() {
				el.classList.add('is-img-loaded');
			};
			poster.src = el.dataset.poster;
			poster.setAttribute('alt','');
			img.appendChild( poster );

		} else {

			const video = document.createElement('video');
			video.src = getVideoSrc( el.dataset );
			video.preload = "metadata";
			video.muted = true;
			video.loop = true;
			video.control = true;
			video.poster = el.dataset.poster;
			video.setAttribute("playsinline", "");
			video.setAttribute("muted", "");

			//
			img.appendChild( video );
			el.classList.add('is-video-loaded');

			//
			if( !IS_REDUCED ) onPlayVideo( video );

		}

	}
}



//
window.onLoadImage = function( el, webp, draw = false ){

	el.classList.add('js-loading-img');

	let $bg = el.querySelector('.bg-img');
	if( el.classList.contains('bg-img') ){
		$bg = el;
	}

	//
	const alt = '';
	const $img = el.querySelectorAll('.img');

	//
	const isBg = $bg.dataset.bgD2x ? true : false;
	const d1x = isBg ? $bg.dataset.bgD1x : $bg.dataset.d1x;
	const d2x = isBg ? $bg.dataset.bgD2x : $bg.dataset.d2x;
	const mob = isBg ? $bg.dataset.bgMob : $bg.dataset.mob;

	//
	const _webp = el.dataset.webp ? parseInt(el.dataset.webp) : true;

	//
	const src = getImageSrc( d1x, d2x, mob, _webp );
	el.classList.add('is-img-load-start');
	if( draw ){

		const img = new Image();
		img.crossOrigin = "Anonymous";
		img.onload = function() {
			el.classList.add('is-img-loaded');
		};
		img.src = src;
		img.setAttribute('alt',alt);
		for (let i = 0; i < $img.length; i++) {
			if( isBg ){
				$img[i].style.backgroundImage = 'url('+ src +')';
			} else {
				$img[i].appendChild( img );
			}
		}

	} else {

		if( isBg ){
			for (let i = 0; i < $img.length; i++) {
				$img[i].style.backgroundImage = 'url('+ src +')';
			}
		} else {
			const img = new Image();
			img.crossOrigin = "Anonymous";
			img.src = src;
			img.setAttribute('alt',alt);
			for (let i = 0; i < $img.length; i++) {
				$img[i].appendChild( img );
			}
		}
		el.classList.add('is-img-loaded');

	}

}


































































































































































































































































































