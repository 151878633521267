const environment = process.env.NODE_ENV || 'production';
const envSet = require(`~/api/env.${environment}.js`);

//
export const setProjects = ({ commit, state }, response ) => {

	for (let i = 0; i < response.length; i++) {
		const v = response[i];
		v.index = (i+1);
		let words = []
		for (let n = 0; n < v.titles.length; n++) {
			const line = Array(v.titles[n]);
			for (let l = 0; l < line.length; l++) {
				const w = line[l].split(" ");
				words.push( w );
			}
		}
		v.titles = words;
	}
	commit("UPDATE_PROJECTS", response );

};


// 配列をシャッフル
function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}


// faqをシャッフルし、orderに従って再配置する関数
function shuffleAndOrderFaq(faq) {
	let unorderedFaqs = faq.filter(item => item.order === false);
	const orderedFaqs = faq.filter(item => typeof item.order === 'number');
	unorderedFaqs = shuffleArray(unorderedFaqs);
	const result = new Array(faq.length).fill(null);
	orderedFaqs.forEach(item => {
		result[item.order - 1] = item;
	});
	let unorderedIndex = 0;
	for (let i = 0; i < result.length; i++) {
		if (result[i] === null) {
			result[i] = unorderedFaqs[unorderedIndex];
			unorderedIndex++;
		}
	}
	return result;
}


//
export const fetchChatFaq = async ({ commit, getters }, { onLoaded = null }) => {

	//
	if (getters.chatAi.faq.loaded) {
		return;
	}

	//
	try {

		const response = await fetch( envSet.api.faq + '?_=' + new Date().getTime(), {
			cache: "no-store"
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.error || 'Network response was not ok');
		}

		const data = await response.json();

		if (data.error) {
			throw new Error(data.error);
		}

		const faq = [];
		data.results.forEach((v) => {

			//
			let _Question_ja = '';
			let _Question_en = '';
			let _Answer_ja   = '';
			let _Answer_en   = '';

			//
			const Question_ja = v.properties.Question_ja[ v.properties.Question_ja.type ];
			const Question_en = v.properties.Question_en[ v.properties.Question_en.type ];
			const Answer_ja = v.properties.Answer_ja[ v.properties.Answer_ja.type ];
			const Answer_en = v.properties.Answer_en[ v.properties.Answer_en.type ];
			const Disable = v.properties.Disable[ v.properties.Disable.type ];
			let Order = v.properties.Order[ v.properties.Order.type ] ? Number(v.properties.Order[ v.properties.Order.type ]) : false;
			if( Order > data.results.length ){
				Order = false;
			}

			//
			if( !Disable ){

				if( Question_ja.length ){
					_Question_ja = Question_ja[0].plain_text;
				}
				if( Answer_ja.length ){
					_Answer_ja = Answer_ja[0].plain_text;
				}
				if( Question_en.length ){
					_Question_en = Question_en[0].plain_text;
				}
				if( Answer_en.length ){
					_Answer_en = Answer_en[0].plain_text;
				}

				faq.push({
					order : Order,
					isUsed : false, // 質問済み
					question : {
						ja : _Question_ja,
						en : _Question_en
					},
					answer : {
						ja : _Answer_ja,
						en : _Answer_en
					}
				});

			}

		});

		//
		const shuffledFaq = shuffleAndOrderFaq(faq);
		commit('SET_CHAT_FAQ', shuffledFaq);

		//
		commit('SET_IS_FAQ_LOADED', true);	


		//
		if (typeof onLoaded === 'function') {
			onLoaded();
		}

	} catch (error) {

		commit('SET_IS_FAQ_LOADED', false);

	}

};
export const updateFaqStatus = ({ commit }, payload) => {
	commit('UPDATE_FAQ_STATUS', payload);
};








//
export const fetchChatSetting = async ({ commit, getters }, { onLoaded }) => {

	//
	if (getters.chatAi.setting.loaded) {
		return;
	}

	//
	try {

		const response = await fetch( envSet.api.setting + '?_=' + new Date().getTime(), {
			cache: "no-store"
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.error || 'Network response was not ok');
		}
		const data = await response.json();
		if (data.error) {
			throw new Error(data.error);
		}

		const setting = [];
		data.results.forEach((v) => {
			const Content_en = v.properties.Content_en;
			const Content_ja = v.properties.Content_ja;

			// 英語タブに入力されている場合
			if(　Content_en[Content_en.type].length ) {
				setting.push({
					role: 'system',
					content: Content_en[Content_en.type][0].plain_text,
				});
				// } else if (Content_ja[Content_ja.type].length) {
				// 	setting.push({
				// 		role: 'system',
				// 		content: Content_ja[Content_ja.type][0].plain_text,
				// 	});
			}

		});

		commit('SET_CHAT_SETTING', setting);
		commit('SET_IS_SETTING_LOADED', true);

		//
		if (typeof onLoaded === 'function') {
			onLoaded();
		}

	} catch (error) {

		commit('SET_IS_SETTING_LOADED', false);

	}

};











