/*
██╗    ██╗███████╗██████╗  ██████╗ ██╗
██║    ██║██╔════╝██╔══██╗██╔════╝ ██║
██║ █╗ ██║█████╗  ██████╔╝██║  ███╗██║
██║███╗██║██╔══╝  ██╔══██╗██║   ██║██║
╚███╔███╔╝███████╗██████╔╝╚██████╔╝███████╗
 ╚══╝╚══╝ ╚══════╝╚═════╝  ╚═════╝ ╚══════╝ resource
*/

window.RESOURCE = {

	items : {

		gekkeiju : {
			mergeGeometry : true,
			material : null,
			geometry : null,
			meshes : [],
			src: '/gekkeiju/gekkeiju2.glb',
		},
		particle : {
			mergeGeometry : true,
			material : null,
			geometry : null,
			meshes : [],
			src: '/particle/nuts-set.glb',
		},

		// About Eight
		about8Plants : {
			mergeGeometry : true,
			material : null,
			geometry : null,
			meshes : [],
			src: '/about/1/about-plant2.glb',
		},
		eight : {
			meshes : [],
			src: '/about/1/about-8.glb',
		},

		// About Footer
		phone : {
			meshes : [],
			src: '/device/iphone.gltf?2',
		},
		desktop : {
			meshes : [],
			src: '/device/desktop.gltf',
		},
		tablet : {
			meshes : [],
			src: '/device/ipad.gltf',
		},
		eyes : {
			meshes : [],
			src: '/eye/eyesFlat.glb',
		},

		aboutFooterPlants : {
			mergeGeometry : true,
			material : null,
			geometry : null,
			meshes : [],
			src: '/about/2/field2.glb',
		},

		// Home Footer
		frame : {
			meshes : [],
			src: '/frame/frame.glb',
		},
		homeFooterPlants : {
			mergeGeometry : true,
			material : null,
			geometry : null,
			meshes : [],
			src: '/home/1/stage-plants2.glb',
		},
	},

	animals : {
		lizard: {
			material : null,
			geometry : null,
			src : '/lizard/d/lizard.gltf',
			scale: .6
		},
		raccoon: {
			material : null,
			geometry : null,
			src : '/raccoon/g/raccoon.gltf',
			scale: .7
		},
		rabbit: {
			material : null,
			geometry : null,
			src : '/rabbit/k/rabbit.gltf',
			scale: .8
		},
		cat: {
			material : null,
			geometry : null,
			src : '/cat/h/cat.gltf',
			scale: .8
		},
		fox: {
			material : null,
			geometry : null,
			src : '/fox/e/fox.gltf',
			scale: .7
		},
		goat: {
			material : null,
			geometry : null,
			src : '/goat/f/goat.gltf',
			scale: .7
		},
		koala: {
			material : null,
			geometry : null,
			src : '/koala/d/koala.gltf',
			scale: .7
		},
		hachi: {
			material : null,
			geometry : null,
			src : '/hachi/e/hachi.gltf',
			scale: .7
		}
	},

	office : {
		items : {
			meshes : [],
			src: '/office/office-items2.glb?13',
		},
		floor : {
			meshes : [],
			src: '/office/office-floor3.gltf',
		},
		desk : {
			meshes : [],
			src: '/office/office-desk2.glb',
		},
		// chair : {
		// 	meshes : [],
		// 	src: 'office/chair.glb',
		// }
	},

	textures : {


		// desktop_hover : {
		// 	el : null,
		// 	src: 'display/desktop.mp4',
		// 	tex: null,
		// 	mediaType : 'video'
		// },
		// tablet_default : {
		// 	src: {
		// 		d2x : 'display/tablet@2x.jpg',
		// 		d1x : 'display/tablet@1x.jpg',
		// 		mob : 'display/tablet@1x.jpg',
		// 	},
		// 	tex: null,
		// 	mediaType : 'image'
		// },
		// tablet_hover : {
		// 	el : null,
		// 	src: 'display/tablet.mp4',
		// 	tex: null,
		// 	mediaType : 'video'
		// },
		// phone_default : {
		// 	src: {
		// 		d2x : 'display/mobile@2x.jpg',
		// 		d1x : 'display/mobile@1x.jpg',
		// 		mob : 'display/mobile@1x.jpg',
		// 	},
		// 	tex: null,
		// 	mediaType : 'image'
		// },
		// phone_hover : {
		// 	el : null,
		// 	src: 'display/mobile.mp4',
		// 	tex: null,
		// 	mediaType : 'video'
		// },

		//==================================
		// GROUND
		//==================================
		ground_home_footer : {
			src: {
				d2x : '/ground/homeFooter@2x.jpg',
				d1x : '/ground/homeFooter@1x.jpg',
				mob : '/ground/homeFooter@1x.jpg',
			},
			tex: null,
			mediaType : 'image'
		},
		ground_about_footer : {
			src: {
				d2x : '/ground/aboutFooter@2x.jpg',
				d1x : '/ground/aboutFooter@1x.jpg',
				mob : '/ground/aboutFooter@1x.jpg',
			},
			tex: null,
			mediaType : 'image'
		},
		ground_about_eight : {
			src: {
				d2x : '/ground/aboutEight@2x.jpg',
				d1x : '/ground/aboutEight@1x.jpg',
				mob : '/ground/aboutEight@1x.jpg',
			},
			tex: null,
			mediaType : 'image'
		},



		//==================================
		// HOME
		//==================================
		desktop_index_default : {
			el : null,
			// src: 'display/desktop.mp4?1',
			src: '/display/desktop_home.mp4',
			tex: null,
			mediaType : 'video'
			// src: {
			// 	d2x : 'display/desktop_home@2x.jpg',
			// 	d1x : 'display/desktop_home@1x.jpg',
			// 	mob : 'display/desktop_home@1x.jpg',
			// },
			// tex: null,
			// mediaType : 'image'
		},
		phone_index_default : {
			el : null,
			src: '/display/mobile_home.mp4?2',
			tex: null,
			mediaType : 'video'
		},

		//==================================
		// ABOUT
		//==================================
		desktop_about_default : {
			el : null,
			src: '/display/desktop.mp4?1',
			tex: null,
			mediaType : 'video'
		},
		tablet_about_default : {
			el : null,
			src: '/display/tablet.mp4',
			tex: null,
			mediaType : 'video'
		},
		phone_about_default : {
			el : null,
			src: '/display/mobile.mp4',
			tex: null,
			mediaType : 'video'
		},

	},

	envMaps: {
		none: {
			tex: null,
			src: []
		},
		// c1: {
		// 	tex: null,
		// 	src: [
		// 		{
		// 			d2x : '/c1-1@2x.jpg',
		// 			d1x : '/c1-1@1x.jpg',
		// 			mob : '/c1-1@mob.jpg'
		// 		},
		// 		{
		// 			d2x : '/c1-2@2x.jpg',
		// 			d1x : '/c1-2@1x.jpg',
		// 			mob : '/c1-2@mob.jpg'
		// 		},
		// 		{
		// 			d2x : '/c1-3@2x.jpg',
		// 			d1x : '/c1-3@1x.jpg',
		// 			mob : '/c1-3@mob.jpg'
		// 		},
		// 		{
		// 			d2x : '/c1-4@2x.jpg',
		// 			d1x : '/c1-4@1x.jpg',
		// 			mob : '/c1-4@mob.jpg'
		// 		},
		// 		{
		// 			d2x : '/c1-5@2x.jpg',
		// 			d1x : '/c1-5@1x.jpg',
		// 			mob : '/c1-5@mob.jpg'
		// 		},
		// 		{
		// 			d2x : '/c1-6@2x.jpg',
		// 			d1x : '/c1-6@1x.jpg',
		// 			mob : '/c1-6@mob.jpg'
		// 		}
		// 	]
		// },
		c3: {
			tex: null,
			src: [
				{
					d2x : '/c3-1@2x.jpg',
					d1x : '/c3-1@1x.jpg',
					mob : '/c3-1@mob.jpg'
				},
				{
					d2x : '/c3-2@2x.jpg',
					d1x : '/c3-2@1x.jpg',
					mob : '/c3-2@mob.jpg'
				},
				{
					d2x : '/c3-3@2x.jpg',
					d1x : '/c3-3@1x.jpg',
					mob : '/c3-3@mob.jpg'
				},
				{
					d2x : '/c3-4@2x.jpg',
					d1x : '/c3-4@1x.jpg',
					mob : '/c3-4@mob.jpg'
				},
				{
					d2x : '/c3-5@2x.jpg',
					d1x : '/c3-5@1x.jpg',
					mob : '/c3-5@mob.jpg'
				},
				{
					d2x : '/c3-6@2x.jpg',
					d1x : '/c3-6@1x.jpg',
					mob : '/c3-6@mob.jpg'
				}
			]
		},
	},

	progress : {
		wrap : null,
		bar  : null,
		num  : null,
		p    : 0,
	},

	logo : {
		wrap : null,
		elems : null
	},

	text : ['One','Two','Three','Four','Five','Six','Seven','Eight'],

	onProgressStatic( _p ){
		const _this = this;
		let p = Math.floor( _p );
		this.progress.num.innerText = this.text[p];
	},

	onProgress( itemsLoaded, itemsTotal ){
		const _this = this;
		let p = Math.floor( itemsLoaded/itemsTotal * (this.text.length-1) );
		if( STATIC.enable ){
			p = Math.floor( STATIC.max + itemsLoaded/itemsTotal * ((this.text.length-1)-STATIC.max) );
		}
		this.progress.num.innerText = this.text[p];
	},

	isloaded : false,

	onLoaded(){

		if( !IS_REDUCED ){

			for (let i = 0; i < this.logo.elems.length; i++) {
				const tl = gsap.timeline();

				tl.to( this.logo.elems[i], {
					duration : .6,
					ease : 'power2.in',
					y : '-120%',
					onComplete(){
						if( $html.classList.contains('is-loading-a') ){
							$html.classList.remove('is-loading-a');
							$html.classList.add('is-loaded');
							onInitThird();
						}
					}
				});
				tl.set( this.logo.elems[i], {
					y : '130%',
				});
				tl.to( this.logo.elems[i], {
					duration : 1.4,
					delay    : 0.0333 * i,
					ease : 'power2.out',
					y : '0%'
				});
			}

			gsap.to( $splash, {
				duration : .6,
				ease     : 'power2.out',
				delay    : .5,
				opacity  : 1,
				onComplete : () =>{
					this.isloaded = true;
					BACKGL.onInview();
				}
			});

		} else {

			//
			$html.classList.remove('is-loading-a');
			$html.classList.add('is-loaded');
			onInitThird();

			//
			for (let i = 0; i < this.logo.elems.length; i++) {
				gsap.set( this.logo.elems[i], {
					y : '0%',
					scale : 1
				});
			}

			//
			gsap.set( $splash, {
				opacity  : 1,
				onComplete : () =>{
					this.isloaded = true;
					BACKGL.onInview();
				}
			});

		}

	},

	once(){
		this.progress.wrap  = document.querySelector('.Sp');
		this.progress.num   = document.querySelector('.js-p');
		this.logo.wrap = document.querySelector('.ui-name');
		this.logo.elems = this.logo.wrap.querySelectorAll('.js-s');
	},

	onInit(){

		const _this = this;

		//
		if( DETECT.device.any ){

			//
			this.loadingManagerSecond = new THREE.LoadingManager();
			this.loadingManagerSecond.onProgress = ( url, itemsLoaded, itemsTotal ) => {
				_this.onProgress( itemsLoaded, itemsTotal );
			};
			this.loadingManagerSecond.onLoad = () => {
				onInitSecond();
				BACKGL.onLoadedSecond();
				this.onLoaded();
			}
			this.loadCubeTexture();
			this.onLoadAnimals();

		} else {

			//
			this.loadingManagerFirst = new THREE.LoadingManager();
			this.loadingManagerFirst.onLoad = () => {
				this.loadCubeTexture();
				this.onLoadAnimals();
				this.onLoadItem();
			}

			//
			this.loadingManagerSecond = new THREE.LoadingManager();
			this.loadingManagerSecond.onProgress = ( url, itemsLoaded, itemsTotal ) => {
				_this.onProgress( itemsLoaded, itemsTotal );
			};
			this.loadingManagerSecond.onLoad = () => {
				this.loadingManagerThird = new THREE.LoadingManager();
				this.loadingManagerThird.onProgress = ( url, itemsLoaded, itemsTotal ) => {};
				this.loadingManagerThird.onLoad = () => {
					BACKGL.onLoadedThird();
					this.onLoaded();
				}
				onInitSecond();
				BACKGL.onLoadedSecond();
				this.onLoadOffice();
			}

			this.onLoadTextures();

		}

	},

	onLoadItem(){

		const _this = this;

		Object.keys( this.items ).forEach((key) => {

			const v = this.items[key];

			const loader = new GLTFLoader( this.loadingManagerSecond );

			loader.setDRACOLoader( dracoLoader );

			loader.load( PATH.models + v.src , (gltf) => {

				//
				// console.log('-------------------------------');
				// console.log( v, gltf );

				if( v.mergeGeometry ){

					const geometries = [];
					gltf.scene.traverse( child => {
						if( child.type === 'Mesh' ) {
							v.material = child.material;
							geometries.push(child.geometry);
							if( child.geometry ) child.geometry.dispose();
							if( child.material ) child.material.dispose();
							if( child.texture ) child.texture.dispose();
						}
					});

					const geometry = BufferGeometryUtils.mergeBufferGeometries( geometries, true );
					geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );
					geometry.name = key;
					geometry.center();
					v.geometry = geometry;

				} else {

					const meshes = [];
					gltf.scene.traverse( child => {
						if( child.type === 'Mesh' ) {
							const c = _this.getDisplay( key, child );
							meshes.push( c );
							if( child.geometry ) child.geometry.dispose();
							if( child.material ) child.material.dispose();
							if( child.texture ) child.texture.dispose();
						}
					});
					v.meshes = meshes;

					for (let i = 0; i < gltf.scene.children.length; i++) {
						const mesh = gltf.scene.children[i];
						gltf.scene.remove( mesh );
					}

				}

				gltf.scene = null;

			});

		});
	},

	onLoadTextures(){
		Object.keys( this.textures ).forEach((key) => {
			const v = this.textures[key];
			switch( v.mediaType ){
				case 'video':
					v.el = document.createElement('video');
					v.el.src = CDN + PATH.tex + v.src;
					v.el.preload = "none";
					v.el.muted = true;
					v.el.loop = true;
					v.el.crossOrigin = 'anonymous';
					//v.el.play();
					v.tex = new THREE.VideoTexture( v.el );
				break;
				case 'image':
					v.tex = new THREE.TextureLoader( this.loadingManagerFirst ).load( getImageSrc( CDN + PATH.tex + v.src.d1x, CDN + PATH.tex + v.src.d2x, CDN + PATH.tex + v.src.mob, true ) );
					v.tex.magFilter = THREE.LinearFilter;
					v.tex.minFilter = THREE.LinearFilter;
				break;
			}
		});

	},

	onLoadAnimals(){

		const _this = this;
		const modelArray = _this.models;

		//====================================
		// MATERIAL
		//====================================

		//
		Object.keys( this.animals ).forEach((key) => {

			const loader = new GLTFLoader( this.loadingManagerSecond );
			loader.setDRACOLoader( dracoLoader );
			loader.load(
				PATH.models + _this.animals[key].src,
				gltf => {

					const geometries = [];
					// const attrCount = [];
					gltf.scene.traverse( child => {
						if (child.type === 'Mesh') {
							child.geometry.name = child.material.name
							geometries.push(child.geometry);
							// child.geometry.count = child.geometry.attributes.position.array.length;
							// attrCount.push(child.geometry.count);
							_this.animals[key].map = child.material.map;
							gltf.scene.remove(child);
							if( child.geometry ) child.geometry.dispose();
							if( child.material ) child.material.dispose();
							if( child.texture ) child.texture.dispose();
						}
					});

					//
					for (let i = 0; i < gltf.scene.children.length; i++) {
						const mesh = gltf.scene.children[i];
						gltf.scene.remove( mesh );
					}

					//====================================
					// GEOMETRY
					//====================================
					const geometry = BufferGeometryUtils.mergeBufferGeometries( geometries, true );
					geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );
					geometry.name = key;


					//
					// const clamp = (num, min, max) => num <= min ? min : num >= max ? max : num;
					// const map = (x, a, b, c, d) => clamp((x - a) * (d - c) / (b - a) + c, Math.min(c,d), Math.max(c,d));
					// geometry.groups.forEach((el,i) => {
					// 	el.start = (attrCount[i-1]) ? (attrCount[i-1]) : 0;
					// 	el.count = (attrCount[i]);
					// 	geometry.total = el.start + el.count;
					// })
					// geometry.groups.forEach((el,i) => {
					// 	const stt = map(el.start, 0, geometry.total, 0, geometry.attributes.position.count);
					// 	const cnt = map(el.count, 0, geometry.total, 0, geometry.attributes.position.count);
					// 	geometry.groups[i] = {start: stt, count: cnt, materialIndex: el.materialIndex}
					// })


					// var mergeGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, true);
					// var mergeVertices = BufferGeometryUtils.mergeVertices( mergeGeometry );
					// mergeVertices.computeVertexNormals();
					// var geometry = mergeVertices;
					// geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );
					// geometry.name = key

					//
					geometry.center();
					_this.animals[key].geometry = geometry;
					_this.animals[key].material = null;

					//
					gltf.scene = null;

				},
				xhr => {
				},
				error => {
					console.log(`error: ${ _this.animals[key].src }`);
				}
			);
		});
	},

	onLoadOffice(){

		Object.keys( this.office ).forEach((key) => {
			const v = this.office[key];
			const loader = new GLTFLoader( this.loadingManagerThird );
			// loader.setMeshoptDecoder(MeshoptDecoder);
			loader.setDRACOLoader( dracoLoader );
			loader.load( PATH.models + v.src , (gltf) => {
				const meshes = [];
				gltf.scene.traverse( child => {
					if( child.type === 'Mesh' ) {
						meshes.push({
							mesh     : child,
							name     : child.name
						});
					}
				});
				v.meshes = meshes;
			});
		});

	},

	loadCubeTexture(){

		const envArray = this.envMaps;
		Object.keys(envArray).forEach((key) => {
			const array = envArray[key].src;
			const _array = [];
			for (let i = 0; i < array.length; i++) {
				const src = getImageSrc( CDN + PATH.env + array[i].d1x, CDN + PATH.env + array[i].d2x, CDN + PATH.env + array[i].mob, true );
				_array.push( src );
			}
			if( _array.length ) {
				const loader = new THREE.CubeTextureLoader( this.loadingManagerThird );
				envArray[key].tex = loader.load( _array );
			};
		});

	},

	getDisplay( key, child ){

		const _this = this;

		//
		if( key === 'desktop' || key === 'tablet' || key === 'phone' ){

			// 画面削除
			if( child.name === 'display' ){

				const kDefault = key + '_about_default';
				// var kHover   = key + '_hover';

				//
				child.material.map = null;
				child.material.dispose();
				child.material = null;

				//
				child.material = new THREE.ShaderMaterial({
					vertexShader   : `
						varying vec2 vUv;
						void main(){
							vUv = uv;
							gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
						}
					`,
					fragmentShader : `
						varying vec2 vUv;
						uniform sampler2D tDefault;
						void main() {
							vec2 uv = vec2( vUv.x, 1.0 - vUv.y );
							vec4 _tDefault = texture2D( tDefault, uv );
							gl_FragColor = _tDefault;
						}
					`,
					uniforms: {
						tDefault : { value : _this.textures[ kDefault ].tex },
					},
					transparent: false
				});

				//
				child.material.needsUpdate = true;

			}

			return child;

		} else {

			return child;

		}

	}

}
