module.exports = {
	baseUrl : '/',
	staticUrl : '',
	globalUrl : 'https://v3.garden-eight.com',
	cdnUrl    : '',
	cdnDomain : 'https://d1oo4riy5et4sg.cloudfront.net',
	s3Url     : '',
	s3Domain  : 'https://gdn8.s3.ap-northeast-1.amazonaws.com',
	dist      : 'dist/dev',
	api : {
		response : 'http://garden-eight.com/_api/chatApi/getOpenAIResponse/',
		setting  : 'http://garden-eight.com/_api/chatApi/json/setting.json',
		faq      : 'http://garden-eight.com/_api/chatApi/json/faq.json'
	},
	pwa : false
}