/*
 ██████╗██╗      █████╗ ███████╗███████╗
██╔════╝██║     ██╔══██╗██╔════╝██╔════╝
██║     ██║     ███████║███████╗███████╗
██║     ██║     ██╔══██║╚════██║╚════██║
╚██████╗███████╗██║  ██║███████║███████║
 ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝ team all
*/


import vert from '~/assets/js/shader/vert/animals.vert';

const rota = {
	x: Math.random() - 80,
	y: Math.random() - 22,
	z: Math.random() + 40,
}

const ANIMALS = {
	raccoon: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: -2.8, y: 1.6, z: -0.7, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
	},
	fox: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: -3.8, y: -1.2, z: 1.2, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
		rotation: rota
	},
	rabbit: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: -0.2, y: -1.8, z: 1.7, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
	},
	lizard: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: -0.3, y: -0.3, z: 1, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 0.5,
	},
	cat: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: 0.9, y: -3, z: -2.0, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
	},
	goat: {
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: 0.5, y: 0.7, z: -4.2, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
	},
	koala:{
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: 2.5, y: -0.5, z: -1.9, },
		rotation: { x : -80, y : -22, z : 40, },
		scale: 1,
	},
	hachi:{
		visible: true,
		mesh : null, geometry : null, material : null, map : null, origin : null, name : null,
		position: { x: 3.0, y: 2.3, z: -3.2, },
		rotation: { x : -90, y : 20, z : 10, },
		scale: .2,
	}
}

const LIGHTS = [
	{
		color: {
			three : new THREE.Color('#edd0b7'),
		},
		position: { x: 2, y: 7.4, z: 4 },
		intensity: 4,
		distance: 30,
		decay: 1,
		mesh : null,
		// volume : {
		// 	mesh : null,
		// 	anglePower: 3,
		// },
	},
	{
		color: {
			three : new THREE.Color('#6f481f'),
		},
		position: { x: 2, y: -7.8, z: 1.2 },
		intensity: 2,
		distance: 30,
		decay: 1,
		mesh : null,
		// volume : {
		// 	anglePower: 16.0,
		// 	mesh : null,
		// }
	},
]


export class SCENE_TEAM_ALL {

	constructor( useScreen = false, useFbo = false, rw, rh ){

		//
		this.useScreen = useScreen;
		this.useFbo = useFbo;

		//
		this.rw = rw;
		this.rh = rh;

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		if( DETECT.device.any && !GRID.landscape ){
			this.ratioWidth  = this.width;
			this.ratioHeight = this.height * 0.5;
		} else {
			this.ratioWidth  = this.width * this.rw;
			this.ratioHeight = this.height - GRID.w * 2;
		}

		//
		this._RES = HI_RES;

		//
		if( this.useFbo ){
			this.fbo = new THREE.WebGLRenderTarget(
				this.ratioWidth  * this._RES,
				this.ratioHeight * this._RES,
				{ minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter }
			);
		}

		//
		this.scene = new THREE.Scene();
		this.fog = {
			color : COLOR.officeFog.three,
			near : -0.5,
			far  : 3.5
		};
		this.scene.fog = new THREE.Fog( this.fog.color, this.fog.near * this.width / RESCALE.r, this.fog.far * this.width / RESCALE.r );
		this.scene.background = this.fog.color;

		//
		this.fov = 45;
		this.camera = new THREE.PerspectiveCamera( this.fov, this.width / this.height, 1, this.width * 2 / RESCALE.r );
		this.camera.position.z = this.width / RESCALE.r;
		// this.camera = new THREE.OrthographicCamera( -this.height * __ASPECT__/2, this.height * __ASPECT__/2, this.height/2, -this.height/2, -this.width*2, this.width*2 );
		// this.camera.position.z = 1;
		this.scene.add( this.camera );
		// this.controls = new OrbitControls( this.camera, BACKGL.renderer.domElement);

		//
		this.key = Object.keys( RESOURCE.animals );

		//
		this.uniform = {
			envMap : 'c3',
			color : {
				// css : '#af8e77',
				// three : new THREE.Color('#af8e77'),
				css : '#fae1bc',
				three : new THREE.Color('#fae1bc'),
			},
			emissive : {
				// css : '#333333',
				// three : new THREE.Color('#333333'),
				css : '#282222',
				three : new THREE.Color('#282222'),
			},
			roughness: 1,
			metalness: 0,
			reflectivity: .1,
			clearcoat: 0.25,
			clearcoatRoughness: 0.3,

			opacity: 1,
			twistPower: 0,
			twistHeight: 1.0,
			twistAngle: 90.0,
			wavyPower: 0.2,
			wavyRadius: 0.75,
			wavyAngle: 0.1,
		}


		//
		this.each = { ... ANIMALS };


		this.lightGroup = new THREE.Group();
		this.lights = [ ... LIGHTS ];

		//
		this.init();
		this.initMesh();
		this.initLight();
		if( this.useScreen ) this.initScreen();
		this.resize();

	}

	init(){

		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const v = this.each[k];
			const target = RESOURCE.animals[ k ];


			// if( !target.material ){

			// マテリアル未登録

				const material = new THREE.MeshPhysicalMaterial({
					// side               : THREE.DoubleSide,
					color              : this.uniform.color.three,
					emissive           : this.uniform.emissive.three,
					roughness          : this.uniform.roughness,
					metalness          : this.uniform.metalness,
					clearcoat          : this.uniform.clearcoat,
					clearcoatRoughness : this.uniform.clearcoatRoughness,
					reflectivity       : this.uniform.reflectivity,
					envMap             : RESOURCE.envMaps[ this.uniform.envMap ].tex,
					map                : target.map
				});

				material.uniformsNeedUpdate = true;
				material.needsUpdate = true;
				material.onBeforeCompile = (shader) => {
					shader.uniforms.twistPower  = { value : this.uniform.twistPower };
					shader.uniforms.twistHeight = { value : this.uniform.twistHeight };
					shader.uniforms.twistAngle  = { value : this.uniform.twistAngle };
					shader.uniforms.wavyPower   = { value : this.uniform.wavyPower };
					shader.uniforms.wavyRadius  = { value : this.uniform.wavyRadius };
					shader.uniforms.wavyAngle   = { value : this.uniform.wavyAngle };
					shader.vertexShader = vert;
					material.userData.shader = shader;
				};

			// } else {

			// 	// マテリアル登録済み
			// 	var material = target.material.clone();
			// 	material.side = THREE.DoubleSide;
			// 	material.emissive = this.uniform.emissive.three;
			// 	material.roughness = this.uniform.roughness;
			// 	material.metalness = this.uniform.metalness;
			// 	material.clearcoat = this.uniform.clearcoat;
			// 	material.clearcoatRoughness = this.uniform.clearcoatRoughness;
			// 	material.reflectivity = this.uniform.reflectivity;

			// }


			const origin = {
				geometry   : { position: { array: null } },
				deg        : Math.random() * 360,
				position   : { x: 0, y: 0, z: 0 },
				speed      : { x: 0, y: 0, z: 0 },
				twistPower : 0,
				wavyAngle  : 0,
			}

			v.mesh = null;
			v.geometry = target.geometry;
			v.material = material;
			v.origin = origin;
			v.name = 'single';

		}

	}

	initMesh(){

		Object.keys( this.each ).forEach( ( key ) => {

			const v = this.each[ key ];
			v.mesh = new THREE.Mesh( v.geometry, v.material );
			v.mesh.visible = v.visible;

			//==========================================
			// 向き変更（軸を変更）
			// v.geometry.applyMatrix4( new THREE.Matrix4().makeRotationX( Math.PI / 2 ) );

			//==========================================
			// polygonの初期コピー
			const new_arr = v.mesh.geometry.attributes.position.array.slice(0, v.geometry.attributes.position.array.length );
			v.origin.geometry.position.array = new_arr;

			//==========================================
			this.scene.add( v.mesh );

		});

	}

	initLight(){

		this.lights.forEach( (v,i) =>{

			//
			v.mesh = new THREE.PointLight( v.color.three );
			v.mesh.intensity  = v.intensity;
			v.mesh.decay      = v.decay;

			// 20240710
			v.mesh.castShadow = false;

			this.lightGroup.add(v.mesh);

			// //
			// if( v.volume ){
			// 	const geometry = new THREE.CylinderBufferGeometry( 0, this.width * 2, v.distance * this.width * 2, 512);
			// 	geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(0, -geometry.parameters.height / 2, 0));
			// 	geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
			// 	const material = new VolumetricSpotLightMaterial()
			// 	v.volume.mesh = new THREE.Mesh( geometry, material );
			// 	v.volume.mesh.position.x = v.position.x * this.width * 2;
			// 	v.volume.mesh.position.y = v.position.y * this.width * 2;
			// 	v.volume.mesh.position.z = v.position.z * this.width * 2;
			// 	v.volume.mesh.scale.x = v.scale * this.width * 2;
			// 	v.volume.mesh.scale.y = v.scale * this.width * 2;
			// 	v.volume.mesh.scale.z = v.scale * this.width * 2;
			// 	v.volume.mesh.lookAt( new THREE.Vector3(0, 0, 0) );
			// 	v.volume.mesh.material.uniforms.attenuation.value = v.distance * this.width * 2;
			// 	v.volume.mesh.material.uniforms.anglePower.value  = v.volume.anglePower;
			// 	v.volume.mesh.material.uniforms.lightColor.value.set( v.color.three );
			// 	v.volume.mesh.material.uniforms.spotPosition.value.x = v.volume.mesh.position.x;
			// 	v.volume.mesh.material.uniforms.spotPosition.value.y = v.volume.mesh.position.y;
			// 	v.volume.mesh.material.uniforms.spotPosition.value.z = v.volume.mesh.position.z;
			// 	v.volume.mesh.renderOrder = 0;
			// 	this.lightGroup.add( v.volume.mesh );
			// }

		});
		this.scene.add( this.lightGroup );

	}

	initEnvMap(){
		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const v = this.each[k];
			if( !v.material.envMap ) v.material.envMap = RESOURCE.envMaps[ this.uniform.envMap ].tex;
		}
	}

	initScreen(){

		const geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		const material = new THREE.ShaderMaterial({
			vertexShader   : `
				varying vec2 vUv;
				void main(){
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.);
				}
			`,
			fragmentShader : `
				varying vec2 vUv;
				uniform vec2 resolution;
				uniform vec2 scale;
				uniform sampler2D tDiffuse;
				void main() {
					vec4 tA = texture2D( tDiffuse, vUv );
					gl_FragColor = tA;
				}
			`,
			uniforms: {
				scale: { value: { x: 1, y: 1 } },
				resolution: { value: { x: 0, y: 0 } },
				tDiffuse : { value: this.fbo.texture },
			},
			transparent: false,
		});
		this.screen = new THREE.Mesh(geometry, material);

	}

	resize(){

		//
		this.width  = window.innerWidth;
		this.height = window.innerHeight;

		//
		// this.ratioWidth  = this.width * this.rw;
		// this.ratioHeight = this.height - GRID.w*2;
		if( DETECT.device.any && !GRID.landscape ){
			this.ratioWidth  = this.width;
			this.ratioHeight = this.height/2;
		} else {
			this.ratioWidth  = this.width * this.rw;
			this.ratioHeight = this.height - GRID.w * 2;
		}

		//
		this.camera.aspect = this.ratioWidth / this.ratioHeight;
		this.camera.position.z = this.width / RESCALE.r;
		this.camera.far = this.camera.position.z*2;
		this.camera.updateProjectionMatrix();

		//
		this.scene.fog.near = this.fog.near * this.width / RESCALE.r;
		this.scene.fog.far  = this.fog.far * this.width  / RESCALE.r;

		//
		if( this.screen ){
			this.screen.scale.x = this.ratioWidth;
			this.screen.scale.y = this.ratioHeight;
			this.screen.material.uniforms.resolution.value.x = this.ratioWidth;
			this.screen.material.uniforms.resolution.value.y = this.ratioHeight;
		}

		//
		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const v = this.each[k];
			if( v.mesh ){

				//
				v.mesh.scale.x = v.scale;
				v.mesh.scale.y = v.scale;
				v.mesh.scale.z = v.scale;

				//
				v.mesh.rotation.x = v.rotation.x * (Math.PI / 180);
				v.mesh.rotation.y = v.rotation.y * (Math.PI / 180);
				v.mesh.rotation.z = v.rotation.z * (Math.PI / 180);

				//
				v.mesh.position.x = v.position.x;
				v.mesh.position.y = v.position.y;
				v.mesh.position.z = v.position.z;

			}
		}

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.position.x = v.position.x;
				v.mesh.position.y = v.position.y;
				v.mesh.position.z = v.position.z;
				v.mesh.distance   = v.distance;
			}
		});

	}

	render(){

		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const v = this.each[k];
			if( v.mesh.visible ){
				const shader = v.mesh.material.userData.shader;
				if( shader ) shader.uniforms.wavyAngle.value = v.origin.wavyAngle - BACKGL.rad.fast.val;
			}
		}

	}

	inview( visible ){

		//
		this.scene.visible = visible;

		//
		for (let i = 0; i < this.key.length; i++) {
			const k = this.key[i];
			const v = this.each[k];
			if( v.mesh ){
				v.mesh.visible = visible;
			}
		}

		//
		this.lights.forEach( (v,i) =>{
			if( v.mesh ){
				v.mesh.visible = visible;
			}
		});

	}


}









